<template>
  <div>
    <b-jumbotron bg-variant="dark" fluid>
      <Navbar />
      <b-row>
        <b-col>
          <div class="jumbotron-text">
            <h1>Registrar</h1>
          </div>
        </b-col>
      </b-row>
    </b-jumbotron>
    <div class="container">
      <div class="row pt-md-3">
        <div class="col-md-8 offset-md-2">
          <form
            novalidate
            :class="{ 'was-validated': wasValidated }"
            @submit.prevent="handleSubmit"
            v-if="!showSuccess"
          >
            <b-alert
              class="mb-11"
              ref="userDonationAgreementAlert"
              v-model="showUserDonationAgreementRecused"
              variant="info"
              dismissible
              fade
            >
              <h5 class="h5">Recado!</h5>
              <p class="mb-0">Para participar é necessário aceitar os termos do regulamento. 😊</p>
            </b-alert>
            <b-alert class="mb-11" variant="danger" dismissible fade v-model="showError">
              <h5 class="h5">Erro!</h5>
              <p class="mb-0">{{ error }}</p>
            </b-alert>
            <b-alert class="mb-11" variant="danger" dismissible fade v-model="showErrors">
              <h5 class="h5">Erro!</h5>
              <ul class="list-unstyled mb-0">
                <li v-for="(error, index) in errors" :key="index">
                  <div v-for="(errorMsg, index2) in error" :key="index2">{{ errorMsg }}</div>
                </li>
              </ul>
            </b-alert>
            <div class="form-group">
              <label for="nome" class="sr-only">nome completo</label>
              <input
                type="text"
                class="form-control"
                placeholder="nome completo"
                id="nome"
                v-model="user.name"
                minlength="5"
                required
                @keyup="handleKeyUp"
              >
              <div class="invalid-feedback">Obrigatório (mín. 10 caracteres)</div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm">
                <label for="email" class="sr-only">email</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="email"
                  id="email"
                  v-model="user.email"
                  minlength="8"
                  required
                />
                <div class="invalid-feedback">Obrigatório (mín. 8 caracteres)</div>
              </div>
              <div class="form-group col-sm">
                <label for="senha" class="sr-only">senha</label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="senha"
                  id="senha"
                  v-model="user.password"
                  minlength="8"
                  required
                >
                <div class="invalid-feedback">Obrigatório (mín. 8 caracteres)</div>
              </div>
            </div>
            <div class="form-group">
              <label for="sobre" class="sr-only">fale sobre você</label>
              <textarea
                rows="10"
                class="form-control"
                placeholder="fale sobre você"
                aria-describedby="ajudaSobre"
                id="sobre"
                v-model="user.about"
                minlength="8"
                maxlength="300"
                required
              ></textarea>
              <div class="invalid-feedback">Obrigatório (mín. 8 caracteres)</div>
              <small id="ajudaSobre" class="form-text">Máximo de 300 caracteres</small>
            </div>
            <div class="form-row">
              <div class="form-group col-sm">
                <label for="cpf" class="sr-only">cpf</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="cpf (somente números)"
                  id="cpf"
                  v-model="user.document"
                  minlength="11"
                  maxlength="11"
                  required
                  :class="{ 'border-danger': userDocumentInvalid, 'icon-is-invalid': userDocumentInvalid, 'is-invalid': userDocumentInvalid, 'border-success': userDocumentValid, 'icon-is-valid': userDocumentValid }"
                  @keyup="showUserDocumentValidity"
                >
                <div class="invalid-feedback">Obrigatório (somente documento válido)</div>
              </div>
              <!-- <div class="form-group col-sm">
                <label for="rg" class="sr-only">rg</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="rg"
                  id="rg"
                  v-model="user.document_rg"
                  maxlength="13"
                >
                <small id="rg" class="form-text">Não obrigatório</small>
              </div> -->
              <div class="form-group col-sm">
                <label for="celular" class="sr-only">celular com ddd</label>
                <input
                  type="tel"
                  class="form-control"
                  placeholder="celular com ddd"
                  id="celular"
                  v-model="user.cellphone"
                  minlength="10"
                  required
                >
                <div class="invalid-feedback">Obrigatório (mín. 10 caracteres)</div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm">
                <label for="cidade" class="sr-only">cidade</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="cidade"
                  id="cidade"
                  v-model="user.city_name"
                  required
                >
                <div class="invalid-feedback">Obrigatório</div>
              </div>
              <div class="form-group col-sm">
                <label for="estado" class="sr-only">estado</label>
                <select class="custom-select" id="estado" v-model="user.state_name" required>
                  <option disabled value>estado</option>
                  <option value="AC">AC</option>
                  <option value="AL">AL</option>
                  <option value="AM">AM</option>
                  <option value="AP">AP</option>
                  <option value="BA">BA</option>
                  <option value="CE">CE</option>
                  <option value="DF">DF</option>
                  <option value="ES">ES</option>
                  <option value="GO">GO</option>
                  <option value="MA">MA</option>
                  <option value="MG">MG</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="PA">PA</option>
                  <option value="PB">PB</option>
                  <option value="PE">PE</option>
                  <option value="PI">PI</option>
                  <option value="PR">PR</option>
                  <option value="RJ">RJ</option>
                  <option value="RN">RN</option>
                  <option value="RO">RO</option>
                  <option value="RR">RR</option>
                  <option value="RS">RS</option>
                  <option value="SC">SC</option>
                  <option value="SE">SE</option>
                  <option value="SP">SP</option>
                  <option value="TO">TO</option>
                </select>
                <div class="invalid-feedback">Obrigatório</div>
              </div>
              <div class="form-group col-sm">
                <label for="cep" class="sr-only">cep</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="cep"
                  id="cep"
                  v-model="user.zipcode"
                  minlength="8"
                  maxlength="8"
                  required
                >
                <div class="invalid-feedback">Obrigatório (somente números)</div>
              </div>
            </div>
            <!-- <div class="form-group">
              <label for="endereco" class="sr-only">endereço residencial completo</label>
              <input
                type="text"
                class="form-control"
                placeholder="endereço residencial completo"
                id="endereco"
                v-model="user.address"
                minlength="8"
                required
              >
              <div class="invalid-feedback">Obrigatório (mín. 8 caracteres)</div>
            </div> -->
            <div class="form-row">
              <div class="form-group col-sm">
                <label for="twitter" class="sr-only">twitter</label>
                <input 
                  type="url"
                  class="form-control"
                  placeholder="https://twitter.com/seu-perfil"
                  aria-describedby="ajudaTwitter"
                  id="twitter"
                  v-model="user.twitter"
                >
                <small id="ajudaTwitter" class="form-text">Não obrigatório</small>
              </div>
              <div class="form-group col-sm">
                <label for="facebook" class="sr-only">facebook</label>
                <input
                  type="url"
                  class="form-control"
                  placeholder="https://facebook.com/seu-perfil"
                  aria-describedby="ajudaFacebook"
                  id="facebook"
                  v-model="user.facebook"
                >
                <small id="ajudaFacebook" class="form-text">Não obrigatório</small>
              </div>
              <div class="form-group col-sm">
                <label for="instagram" class="sr-only">instagram</label>
                <input
                  type="url"
                  class="form-control"
                  placeholder="https://instagram.com/seu-perfil"
                  aria-describedby="ajudaInstagram"
                  id="instagram"
                  v-model="user.instagram"
                >
                <small id="ajudaInstagram" class="form-text">Não obrigatório</small>
              </div>
            </div>
            <div class="form-row">
              <!-- <div class="form-group col-sm-8">
                <label for="recomendacao" class="sr-only">recomendação</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="indicação (nome do líder mobilizador, se houver)"
                  aria-describedby="ajudaRecomendacao"
                  id="recomendacao"
                  v-model="user.recommended_by"
                >
                <small id="ajudaRecomendacao" class="form-text">Não obrigatório</small>
              </div> -->
              <div class="form-group col-sm-6">
                <label for="racial" class="sr-only">Identificação racial</label>
                <select class="custom-select" id="racial" v-model="user.racial" required>
                  <option value disabled>identificação racial</option>
                  <option value="branco">Branco</option>
                  <option value="preto">Preto</option>
                  <option value="pardo">Pardo</option>
                  <option value="amarelo">Amarelo</option>
                  <option value="indigena">Indígena</option>
                  <option value="outros">Outros</option>
                </select>
                <div class="invalid-feedback">Obrigatório</div>
              </div>
              <div class="form-group col-sm-6">
                <label for="genero" class="sr-only">Identificação de gênero</label>
                <select class="custom-select" id="genero" v-model="user.gender" required>
                  <option value disabled>identificação de gênero</option>
                  <option value="mulher-cis">Mulher-cis</option>
                  <option value="homem-cis">Homem-cis</option>
                  <option value="homem-trans">Homem-trans</option>
                  <option value="mulher-trans">Mulher-trans</option>
                  <option value="travesti">Travesti</option>
                  <option value="nao-binario">Não-binário</option>
                  <option value="outros">Outros</option>
                </select>
                <div class="invalid-feedback">Obrigatório</div>
              </div>
            </div>
            <!-- <div class="form-group">
              <label for="areasDeInteresse" class="sr-only">áreas de interesse</label>
              <input
                type="text"
                class="form-control"
                placeholder="áreas de interesse"
                aria-describedby="ajudaAreasDeInteresse"
                id="areasDeInteresse"
                v-model="user.interests"
              >
              <small id="ajudaAreasDeInteresse" class="form-text">Não obrigatório</small>
            </div> -->
            <fieldset class="form-group">
              <!-- <legend class="col-form-label font-weight-bolder py-0">O Central Veredas ganhou o Prêmio Brasil Criativo 2016 e precisa da sua ajuda. Concorda que sua inscrição converta-se em uma doação de R$ 10,00?</legend> -->
              <legend class="col-form-label font-weight-bolder py-0">
                Concorda com o 
                <a style="text-decoration:underline" href="https://drive.google.com/file/d/1nnuTXCiPgL6T5eIBIdIEfRaqGf-5vQmz/view" target="_blank">
                  <em>regulamento do Prêmio Brasil Criativo</em>
                </a>
                ?
              </legend>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input position-static"
                  type="checkbox"
                  id="donationAgreement"
                  value="0"
                  checked=false
                  v-model="user.donationAgreement"
                  required
                  @change="showUserDonationAgreementValidity"
                >
                <label :class="{ 'text-success': userDonationAgreementAccepted, 'text-info': userDonationAgreementRecused }" class="form-check-label" for="donationAgreement">Concordo com todos os termos do regulamento.</label>
              </div>
              <!-- <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="donationAgreement"
                  id="donationAgreementRadio0"
                  :value="0"
                  v-model="user.donationAgreement"
                  required
                  @change="showUserDonationAgreementValidity"
                >
                <label :class="{ 'text-success': userDonationAgreementAccepted, 'text-info': userDonationAgreementRecused }" class="form-check-label" for="donationAgreementRadio0">Não</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="donationAgreement"
                  id="donationAgreementRadio1"
                  :value="1"
                  v-model="user.donationAgreement"
                  required
                  @change="showUserDonationAgreementValidity"
                >
                <label :class="{ 'text-success': userDonationAgreementAccepted, 'text-info': userDonationAgreementRecused }" class="form-check-label" for="donationAgreementRadio1">Sim</label>
              </div> -->
            </fieldset>
            <div class="d-flex justify-content-center mb-9" v-if="loading">
              <i class="fas fa-circle-notch fa-spin fa-2x"></i>
            </div>
            <div class="d-flex flex-column align-items-stretch flex-sm-row justify-content-between mb-10 mb-md-11">
              <router-link :to="{ name: 'home' }" class="btn btn-outline-primary mb-3 mb-sm-0">
                Voltar
              </router-link>
              <input type="submit" class="btn btn-outline-primary ml-sm-3" value="Cadastrar">
            </div>
          </form>
          <div v-if="showSuccess" class="d-flex flex-column align-items-center">
            <b-alert variant="success" v-model="showSuccess" class="mb-9">
              <h5 class="h5">Sucesso!</h5>
              <p class="mb-0">Foi enviada para {{ user.email }} uma mensagem contendo o link para confirmar o cadastro da sua conta</p>
            </b-alert>
            <router-link :to="{ name: 'home' }" class="btn btn-outline-primary mb-3 mb-sm-0">
              Ir pra home
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Navbar from "@/components/Navbar";

export default {
  name: "EntrepreneurSignUp",
  components: {
    Navbar
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
        name: '',
        document: '',
        document_rg: '',
        cellphone: '',
        phone: '',
        city_name: '',
        state_name: '',
        zipCode: '',
        recommended_by: '',
        gender: '',
        racial: '',
        // address: '',
        twitter: '',
        facebook: '',
        instagram: '',
        about: '',
        // interests: '',
        joinedProjectNetwork: '',
        donationAgreement: ''
      },
      wasValidated: false,
      userDocumentValid: false,
      userDocumentInvalid: false,
      userDonationAgreementAccepted: false,
      userDonationAgreementRecused: false,
      showUserDonationAgreementRecused: false,
      showSuccess: false,
      showErrors: false,
      showError: false,
      errors: [],
      error: "",
      loading: false
    };
  },
  methods: {
    ...mapActions('user', ['register']),
    showUserDonationAgreementValidity() {
      if (this.wasValidated) {
        if (!this.user.donationAgreement) {
          this.userDonationAgreementAccepted = false;
          this.userDonationAgreementRecused = true;
          this.showUserDonationAgreementRecused = true;
          window.location.href = this.$route.path + "#";
        } else {
          this.userDonationAgreementRecused = false;
          this.userDonationAgreementAccepted = true;
        }
      }
    },
    showUserDocumentValidity() {
      if (this.wasValidated) {
        if (!this.checkUserDocumentValidity(this.user.document)) {
          this.userDocumentValid = false;
          this.userDocumentInvalid = true;
        } else {
          this.userDocumentValid = true;
          this.userDocumentInvalid = false;
        }
      }
    },
    checkUserDonationAgreementValidity() {
      if (this.wasValidated && !this.user.donationAgreement) {
        return false;
      } else {
        return true;
      }
    },
    checkUserDocumentValidity(document) {
      const cpf = document.replace(/[^\d]/g, "");
      if (cpf.length !== 11) return false;

      if (cpf == "00000000000" || cpf == "11111111111" || cpf == "22222222222" || cpf == "33333333333" || cpf == "44444444444" || cpf == "55555555555" || cpf == "66666666666" || cpf == "77777777777" || cpf == "88888888888" || cpf == "99999999999") {
        return false;
      }
      let s = 0;
      for (let i = 1; i <= 9; i++) {
        s = s + parseInt(cpf[i - 1]) * (11 - i);
      }
      let r = (s * 10) % 11;
      if (r == 10 || r == 11) r = 0;
      if (r != parseInt(cpf[9])) return false;

      s = 0;
      for (let i = 1; i <= 10; i++) {
        s = s + parseInt(cpf[i-1]) * (12 - i);
      }
      r = (s * 10) % 11;
      if ((r == 10) || (r == 11)) r = 0;
      if (r != parseInt(cpf[10])) return false;

      return true;
    },
    handleKeyUp() {
      if (!this.wasValidated) {
        this.wasValidated = true;
      }
    },
    handleSubmit(e) {
      this.wasValidated = true;
      this.showUserDonationAgreementValidity();
      this.showUserDocumentValidity();
      if (
        e.target.checkValidity() &&
        this.checkUserDonationAgreementValidity() &&
        this.checkUserDocumentValidity(this.user.document)
      ) {
        this.loading = true;
        this.register(this.user)
          .then(response => {
            if (response.data.responseData.success === true || response.data.responseData.success === 'true') {
              this.loading = false;
              this.showSuccess = true;
            }
          })
          .catch(error => {
            if (error.response.data.responseData.errorMessage) {
              this.loading = false;
              this.showError = true;
              this.error = error.response.data.responseData.errorMessage;
            } else {
              this.loading = false;
              this.showErrors = true;
              this.errors = error.response.data.responseData.errors;
            }
            window.location.href = this.$route.path + "#";
          });
      } else {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.form-control.icon-is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23F00' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23F00' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
