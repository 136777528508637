import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Auth from '@/helpers/auth'
import Home from '@/pages/Home'
import Regulation from '@/pages/Regulation'
import PostList from '@/pages/PostList'
import PostDetail from '@/pages/PostDetail'
import EntrepreneurSignUp from '@/pages/EntrepreneurSignUp'
import ValidateEmail from '@/pages/ValidateEmail'
import UserDashboard from '@/pages/UserDashboard'
import Logout from '@/pages/Logout'
import SessionExpired from '@/pages/SessionExpired'
import Recommendation from '@/pages/Recommendation'
import Invite from '@/pages/Invite'
import Rating from '@/pages/Rating'
import NotFound from '@/pages/404'

const routes = [
  {
    path: '/empreendedor/dashboard',
    name: 'entrepreneurDashboard',
    component: UserDashboard
  },
  {
    path: '/curador/dashboard',
    name: 'curatorDashboard',
    component: UserDashboard
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    props: true,
  },
  {
    path: '/ativar-perfil-curador',
    name: 'activateCuratorProfile',
    component: Home,
    props: true,
  },
  {
    path: '/login',
    name: 'login',
    component: Home,
    props: true,
  },
  {
    path: '/mobilizador/:mobilizerSlug',
    name: 'mobilizerDetail',
    component: Home,
    props: true
  },
  {
    path: '/curador/:curatorSlug',
    name: 'curatorDetail',
    component: Home,
    props: true
  },
  {
    path: '/blog',
    name: 'postList',
    component: PostList
  },
  {
    path: '/blog/:postSlug',
    name: 'postDetail',
    component: PostDetail,
    props: true
  },
  // {
  //   path: '/registrar',
  //   name: 'entrepreneurSignUp',
  //   component: EntrepreneurSignUp
  // },
  {
    path: '/validar-email',
    name: 'validateEmail',
    component: ValidateEmail
  },
  {
    path: '/regulamento',
    name: 'regulation',
    component: Regulation
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/sessao-expirada',
    name: 'sessionExpired',
    component: SessionExpired
  },
  {
    path: '/indicacao',
    name: 'recommendation',
    component: Recommendation
  },
  {
    path: '/convite',
    name: 'invite',
    component: Invite
  },
  {
    path: '/avaliacao',
    name: 'rating',
    component: Rating
  },
  {
    path: '/admin',
    name: 'admin',
    beforeEnter() { location.href = process.env.VUE_APP_ADMIN_ENDPOINT }
  },
  {
    path: '/404',
    name: 'notFound',
    component: NotFound,
  },
  {
    // fallback
    path: '*',
    redirect: { name: 'notFound' }
  }
]

//I see if the token is not expired
Auth.setup()

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history'   //todo: needs to alter the proxy server to read index.html -- (https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations)
})


export default router

// hooks

router.beforeEach((to, from, next) => {
  // list of public pages that don't require authentication
  const publicPages = [
    'home',
    'login',
    'activateCuratorProfile',
    'mobilizerDetail',
    'curatorDetail',
    'postList',
    'postDetail',
    'entrepreneurSignUp',
    'validateEmail',
    'sessionExpired',
    'recommendation',
    'invite',
    'rating',
    'regulation',
    'admin',
    'notFound',
  ]

  // test if desired route needs authentication
  const authRequired = !publicPages.includes(to.name)

  // get to know if user is authenticated or not
  const isLoggedIn = store.getters['user/isLoggedIn']
  const isCurator = store.getters['user/isCurator']
  const isEntrepreneur = store.getters['user/isEntrepreneur']

  // if needs authentication and user isn't logged in, push to home
  if (authRequired) {
    if (!isLoggedIn) {
      return next({ name: 'home' })
    } else if (to.name === 'entrepreneurDashboard' && !isEntrepreneur) {
      return next({ name: 'home' })
    } else if (to.name === 'curatorDashboard' && !isCurator) {
      return next({ name: 'home' })
    }
  }

  // allow navigation
  next()
})
