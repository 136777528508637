<template>
  <!--<title><?= $title ?></title>-->
  <!-- <meta name="description" content="<?= $description ?>"> -->
  <!--<meta name="keywords" content="<?= implode(', ', $keywords) ?>">-->
  <!--<meta name="revisit-after" content="<?= $revisit_after ?>">-->

    <full-page ref="fullpage" :options="fullpageOptions" id="fullpage">

      <div data-anchor="inicio" class="section"> 
        <div class="row mx-0 min-vh-100">
          <div class="hero col-md-7 col-lg-8 fullscreen-bg">
            <video class="fullscreen-bg__video" data-autoplay muted loop>
              <source src="@/assets/videos/hero-bg.mp4" type="video/mp4">
            </video>
            <!-- <nav class="nav align-items-start"> -->
            <nav class="nav flex-column align-items-start">
              <!-- <a class="h2" href="#o-premio">O prêmio</a> -->
              <!-- <a class="h2" href="#categorias">Categorias</a> -->
              <!-- <a class="h2" href="#jurados">Jurados</a> -->
              <!-- <a class="h2" href="#comunidade">Comunidade</a> -->
              <!-- <a class="h2" href="#mobilizadores">Mobilizadores</a> -->
              <!-- <a class="h2" href="#etapas">Etapas</a> -->
              <!-- <a class="h2" href="#noticias" v-if="loading || viewData.posts.length">Notícias</a> -->
               <!-- <a class="h2" href="#regulamento">Regulamento</a> -->
              <!-- <a class="h2" href="#resultados">Resultados</a> -->
              <p class="h2 mt-6 col-3">O maior ecossistema criativo do país</p>
              <!-- <p class="h2 mt-6">FAÇA PARTE DA FESTA! <br><br>
                                  24/JAN 18H00 <br><br>
                                  Centro Cultural São Paulo</p> -->

              <!-- <div class="row d-flex align-items-end mt-6 mb-6 ml-1" style="height: 100px;">
                <a class="btn btn-outline-primary btn-home" href="//premiobrasilcriativo.com.br/blog/premio-brasil-criativo-apresenta-vencedores-da-sua-edicao-2023">
                  Conheça os vencedores
                </a>
              </div>  -->
              
              <!-- <p class="h3 mt-6 pl-3">PARTICIPE DA CERIMÔNIA DE PREMIAÇÃO EM SÃO PAULO</p> -->

              <template v-if="customData.home.countdown_date">
                <div class="mt-6 pl-3">
                  <template v-if="getTime(customData.home.countdown_date) <= 0">
                    <h3 style="color: #ff0061;">{{ customData.home.countdown_finish }}</h3>
                  </template>
                  <template v-else>
                    <countdown :time="getTime(customData.home.countdown_date)" tag="h3">
                      <template slot-scope="props" class="text-uppercase">
                        <h3 style="color: #ff0061;">
                        {{ customData.home.countdown_label }} {{props.days}} dias, {{props.hours}} horas e {{props.minutes}} minutos
                        </h3>
                      </template>
                    </countdown>
                  </template>
                </div>
              </template>

              
            </nav>
            <!-- <div class="sponsor">
              <img class="sponsor-img text-center" src="@/assets/images/barra-logos.png" alt="Chancelas">
            </div> -->
            <div class="sponsors">
              <div class="sponsor">
                <figure class="figure">
                  <a href="" target="_blank">
                    <img class="sponsor-img" src="@/assets/images/arte-lei-de-incentivo-a-cultura.png" alt="Arte da Lei de Incentivo à Cultura">
                  </a>
                </figure>
              </div>
              <div class="sponsor">
               <figure class="figure">
                  <figcaption class="sponsor-caption align-self-start">Patrocínio</figcaption>
                  <div class="mx-n4">
                    <a href="//www.3m.com.br/" target="_blank">
                      <img class="sponsor-img" src="@/assets/images/logo-3m.png" alt="Logo da 3M">
                    </a>
                  </div>
                </figure>
              </div>

            
              <div class="sponsor">
                <figure class="figure">
                  <figcaption class="sponsor-caption" style="margin-bottom: 0px;">Organização</figcaption>
                  <a href="//elo3.com.br/" target="_blank">
                    <img class="sponsor-img px-4" src="@/assets/images/logo_elo3_rosa.png" alt="Logo elo 3">
                  </a>
                </figure>
              </div>
             
             
              <div class="sponsor">
                <figure class="figure">
                  <figcaption class="sponsor-caption align-self-start" style="margin-bottom: 0px;">Realização</figcaption>
                  <div class="mx-n4">
                    <a href="//pt.wikipedia.org/wiki/Organiza%C3%A7%C3%A3o_Mundial_da_Criatividade" target="_blank">
                      <img class="sponsor-img" src="@/assets/images/logo-wco-white.png" alt="World Creativity Organization">
                    </a>
                    <a href="//www.gov.br/turismo/pt-br" target="_blank">
                      <img class="sponsor-img" src="@/assets/images/secretaria-e-turismo.png" alt="Arte Pátria Amada Brasil">
                    </a>
                  </div>
                </figure>
              </div>
            </div>
          </div>
          <TheHeroMobile :loading="loading" :custom-data="customData" :view-data="viewData" :login="showLogin" :activate-curator-profile="showActivateCuratorProfile"/>
        </div>
      </div>

      <!--<div data-anchor="video" class="container mt-6 section min-vh-100">
          <div class="mx-auto video-container">
            <iframe src="https://www.youtube.com/embed/NfuiJDpfcn4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
      </div>-->

       <div data-anchor="o-premio" class="section">
        <div class="row mx-0 min-vh-100">
          <div class="about-content col-lg-7">
            <div class="about-copy">
              <h2 class="h1 mb-11">O prêmio</h2>
              <div v-html="customData.home.about_text" v-if="customData.home && customData.home.about_text"></div>
            </div>
            <div class="social-links d-none d-lg-flex" v-if="customData.general && (customData.general.facebook || customData.general.twitter || customData.general.instagram || customData.general.linkedin)">
              <a v-if="customData.general.facebook" class="social-link" :href="customData.general.facebook" target="_blank"><i class="fab fa-facebook"></i></a>
              <a v-if="customData.general.twitter" class="social-link" :href="customData.general.twitter" target="_blank"><i class="fab fa-twitter"></i></a>
              <a v-if="customData.general.instagram" class="social-link" :href="customData.general.instagram" target="_blank"><i class="fab fa-instagram"></i></a>
              <a v-if="customData.general.linkedin" class="social-link" :href="customData.general.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a>
            </div>
          </div>
          <div class="about-img col-lg-5 text-right"></div>
        </div>
      </div> 

      <!--<div data-anchor="claudia-leitao" class="section about-content">
        <h2 class="h1">Claudia Leitao</h2>
        <div class="row mx-0">
          <div class="about-content col-lg-4">
            <div class="card mb-7 sub-category">
              <img src="@/assets/images/claudia-leitao.png" class="card-img card-img-translucid category-img" alt="capa categoria">
            </div>
          </div>
          <div class="mt-10 col-lg-6">
            <div class="social-links mb-6 d-none d-lg-flex" >
              <a v-if="customData.general.facebook" class="social-link" :href="customData.general.facebook" target="_blank"><i class="fab fa-facebook"></i></a>
              <a v-if="customData.general.twitter" class="social-link" :href="customData.general.twitter" target="_blank"><i class="fab fa-twitter"></i></a>
              <a v-if="customData.general.instagram" class="social-link" :href="customData.general.instagram" target="_blank"><i class="fab fa-instagram"></i></a>
              <a v-if="customData.general.linkedin" class="social-link" :href="customData.general.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a>
            </div>
            <h3>Líder do Conselho Nacional da Economia Criativa “Brasil Criativo”</h3>
            <p>Cláudia Leitão é mestra em Direito na USP e doutora em Sociologia pela Sorbonne (Paris V). Foi Secretária da Cultura do Estado do Ceará (2003-2006).  Secretária da Economia Criativa do MinC (2011 a 2013). Dirigiu o Observatório de Fortaleza do Instituto de Planejamento da Prefeitura de Fortaleza – IPLANFOR (2017-2020) e foi presidente da Câmara Setorial de Economia Criativa na Agencia de Desenvolvimento do Estado do Ceará - ADECE (2019-2020).</p>
            <p> É membro do Conselho Consultivo da empresa portuguesa Territórios Criativos (2020). É consultora associada do Instituto Alvorada Brasil; é consultora ad hoc em Economia Criativa para a Organização Mundial do Comércio - OMC e para a Conferência das Nações Unidas para o Comércio e Desenvolvimento - UNCTAD((2013 a 2016) É consultora em Economia Criativa para o Serviço Brasileiro de Apoio às Micro e Pequenas Empresas - Sebrae, para governos federal, estaduais e municipais, empresas privadas e outras organizações. É sócia do Centro Internacional Celso Furtado de Políticas para o Desenvolvimento e associada da Tempo de Hermes Projetos Criativos.</p>

          </div>
        </div>
      </div> -->

      <div data-anchor="edna-duisenberg" class="section about-content">
        <h2 class="h1">EDNA DUISENBERG</h2>
        <div class="row mx-0">
          <div class="about-content col-lg-4">
            <div class="card mb-7 sub-category">
              <img src="@/assets/images/edna-duisenberg.jpeg" class="card-img card-img-translucid category-img" alt="capa categoria">
            </div>
          </div>
          <div class="mt-10 col-lg-6">
            <div class="social-links mb-6 d-none d-lg-flex" >
              <a v-if="customData.general.facebook" class="social-link" :href="customData.general.facebook" target="_blank"><i class="fab fa-facebook"></i></a>
              <a v-if="customData.general.twitter" class="social-link" :href="customData.general.twitter" target="_blank"><i class="fab fa-twitter"></i></a>
              <a v-if="customData.general.instagram" class="social-link" :href="customData.general.instagram" target="_blank"><i class="fab fa-instagram"></i></a>
              <a v-if="customData.general.linkedin" class="social-link" :href="customData.general.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a>
            </div>
            <h3>Líder do Conselho Nacional da Economia Criativa “Brasil Criativo”</h3>
            <p>Economista, pesquisadora e conselheira internacional para governos e instituições no âmbito da economia criativa e políticas de desenvolvimento. Desenvolveu carreira internacional na ONU em Genebra ao longo de 30 anos. Fundou e dirigiu o Programa de Economia Criativa da Conferência das Nações Unidas para o Comércio e Desenvolvimento - UNCTAD. Diretora e principal coautora dos primeiros Relatórios de Economia Criativa da ONU de 2008 e 2010, que se tornaram referência mundial para governos e academia. Articulou a agenda política internacional da economia criativa, promovendo reuniões intergovernamentais, implementando projetos de cooperação técnica e assessorando governos e instituições em dezenas de países em todos os continentes.</p>
            <p>É Vice-Presidente da Federação Internacional das Associações de Multimídia. Conselheira associada ao Instituto das Nações Unidas para Treinamento e Pesquisa - UNITAR. Membro do Conselho Internacional do Centro de Políticas das Indústrias Criativas do Reino Unido. Membro do Board do Global Creative Economy Council. Foi membro do júri que elegeu a Capital Mundial do Design. Colabora com universidades na América Latina, Asia, Europa e Estados Unidos. Conferencista internacional e autora de documentos oficiais da ONU e artigos publicados em livros e revistas em diversos países em temas relacionados a cultura, economia criativa, comércio internacional e desenvolvimento sustentável.</p>

          </div>
        </div>
      </div> 

      <div data-anchor="lucas-foster" class="section about-content">
        <h2 class="h1">Lucas Foster</h2>
        <div class="row mx-0 ">
          <div class="about-content col-lg-4">
            <div class="card mb-7 sub-category">
              <img src="@/assets/images/lucas-foster.jpg" class="card-img card-img-translucid category-img" alt="capa categoria">
            </div>
          </div>
          <div class="mt-10 col-lg-6">
            <div class="social-links mb-6 d-none d-lg-flex" >
              <a v-if="customData.general.facebook" class="social-link" :href="customData.general.facebook" target="_blank"><i class="fab fa-facebook"></i></a>
              <a v-if="customData.general.twitter" class="social-link" :href="customData.general.twitter" target="_blank"><i class="fab fa-twitter"></i></a>
              <a v-if="customData.general.instagram" class="social-link" :href="customData.general.instagram" target="_blank"><i class="fab fa-instagram"></i></a>
              <a v-if="customData.general.linkedin" class="social-link" :href="customData.general.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a>
            </div>
            <h3>Idealizador e curador do Prêmio Brasil Criativo, Dia Mundial da Criatividade e Festival Mundial da Criatividade</h3>
            <p>Lucas Foster é psicólogo, ativista, produtor e empresário brasileiro. Fundou em 2014 o Prêmio Brasil Criativo, reconhecido pelo Ministério da Cultura como a premiação oficial da Economia Criativa brasileira. Foi idealizador e produtor executivo do documentário Brasil Criativo.DOC, primeira série exclusiva sobre a economia criativa brasileira, com apoio do Ministério da Cultura.</p>
            <p>Em 2016, lançou o Dia Mundial da Criatividade, movimento ativista em defesa da criatividade como um direito fundamental para acelerar a solução de problemas econômicos, sociais e ambientais. O movimento tornou-se o embrião para a criação do Festival Mundial da Criatividade, considerado, desde 2021, o maior festival colaborativo de criatividade do mundo.</p>
            <p>Em 2022, a Organização Mundial da Criatividade assume a coordenação-geral do Dia Mundial da Criatividade e do Festival Mundial da Criatividade, tendo Foster como sócio-fundador e diretor-geral.</p>

          </div>
        </div>
      </div> 

      <div data-anchor="categorias" class="section">
        <div class="row mx-0 min-vh-100">
          <div class="col p-11">
            <h2 class="h1 mb-11">Categorias</h2>
            <template v-if="viewData.categories && viewData.categories.length">
            <div class="row flex-nowrap no-gutters mx-n11 mx-lg-n2 category" style="overflow-x: auto; overflow-y: hidden;">
              <div class="pl-9 pl-lg-0"></div>
              <div v-for="(category, index) in viewData.categories" :key="index" class="col-10 col-lg-3 px-2">
                <div class="card mb-7 sub-category">
                  <img v-if="category.Image" :src="safe(category.Image.croppedImg).url" class="card-img card-img-translucid category-img" alt="capa categoria">
                  <div class="card-img-overlay card-img-overlay-hover">
                    <div class="card-text" v-html="category.description">
                      {{ category.description }}
                    </div>
                  </div>
                </div>
                <h4 class="h4">{{ category.name }}</h4>
              </div>
              <!-- Card for Startup 
              <div class="col-10 col-lg-3 px-2">
                <div class="card mb-7 sub-category">
                  <img src="//projecthub.com.br/files/c/1675/600-450-1-0.jpg" class="card-img card-img-translucid category-img" alt="capa categoria">
                  <div class="card-img-overlay card-img-overlay-hover">
                    <div class="card-text">
                      <a href="//www.startvc.com.br" target="_blank">
                        StartUps é uma nova categoria especial da 4ª edição do Prêmio Brasil Criativo apresentada em aliança com a STARTVC.
                        Para se inscrever nesta categoria: 
                        Para escolher a categoria StartUps, acesse o site da STARTVC (www.startvc.com.br).
                        Clique em um botão para se inscrever no processo de seletivo para aceleração.
                        Quando o formulário abrir: preencha todos os campos necessários e MARQUE A OPÇÃO “Quero participar da 4ª edição do Prêmio Brasil Criativo, categoria especial StartUps.”
                      </a>
                    </div>
                  </div>
                </div>
                <h4 class="h4">Startups</h4>
              </div>-->
              <div class="pl-9 pl-lg-0"></div>
            </div>
            </template>
          </div>
        </div>
      </div>

      <Curators :curator-slug="curatorSlug" />

      <!-- <Community /> -->

      <Mobilizers :mobilizer-slug="mobilizerSlug" />

      <div data-anchor="etapas" class="section">
        <div class="row mx-0 min-vh-100">
          <div class="col stages">
            <h2 class="h1 mb-11 text-center text-lg-left">Etapas</h2>
            <div class="stages-widget">
              <DateProgressBar ref="dateProgress"></DateProgressBar>
              <!-- <img src="@/assets/images/arte-estreia-documentario-pbc.png"> -->
            </div>
          </div>
        </div>
      </div>

      <!-- <div data-anchor="noticias" class="section" v-if="loading || viewData.posts.length">
        <div class="row mx-0 min-vh-100">
          <div class="col news">
            <h2 class="h1 mb-11">Notícias</h2>
            <div class="news-widget">
              <div class="news-cards">
                <div v-if="viewData.posts[0]" class="card card-horizontal">
                  <router-link :to="{ name: 'postDetail', params: { postSlug: viewData.posts[0]['Content']['slug']} }">
                    
                    <div class="row no-gutters">
                      <div class="col-4">
                        <img class="card-img" alt="capa noticia" :src="viewData.posts[0]['FeaturedImage']['croppedImg'].url">
                      </div>
                      <div class="col-8">
                        <div class="card-body">
                          <h4 class="card-title-news">{{ viewData.posts[0]['Content']['title']}}</h4>
                          <div class="card-text-news"><small>{{ dateFormat(viewData.posts[0]['Content']['publish_date']) }}</small></div>
                          
                          
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div v-if="viewData.posts[1]" class="card card-horizontal">
                  <router-link :to="{ name: 'postDetail', params: {postSlug: viewData.posts[1]['Content']['slug']} }">
                    <img class="card-img card-img-translucid" alt="capa noticia" :src="viewData.posts[1].FeaturedImage.croppedImg.url">
                    <div class="card-img-overlay">
                      <h4 class="card-title-news">{{ viewData.posts[1]['Content']['title'] }}</h4>
                      <div class="card-text-news"><small>{{ dateFormat(viewData.posts[1].Content.publish_date) }}</small></div>
                      
                    </div>
                  </router-link>
                </div>
                <div v-if="viewData.posts[2]" class="card card-horizontal">
                  <router-link :to="{ name: 'postDetail', params: {postSlug: viewData.posts[2].Content.slug} }">
                    <img class="card-img card-img-translucid" alt="capa noticia" :src="viewData.posts[2].FeaturedImage.croppedImg.url">
                    <div class="card-img-overlay">
                      <h4 class="card-title-news">{{ viewData.posts[2]['Content']['title']}}</h4>
                      <div class="card-text-news"><small>{{ dateFormat(viewData.posts[2]['Content']['publish_date']) }}</small></div>
                      
                    </div>
                  </router-link>
                </div>
                <div v-if="viewData.posts[3]" class="card card-horizontal">
                  <router-link :to="{ name: 'postDetail', params: {postSlug: viewData.posts[3]['Content']['slug']} }">
                    <div class="row no-gutters">
                      <div class="col-4">
                        <img class="card-img" alt="capa noticia" :src="viewData.posts[3]['FeaturedImage']['croppedImg'].url">
                      </div>
                      <div class="col-8">
                        <div class="card-body">
                          <h4 class="card-title-news">{{ viewData.posts[3]['Content']['title']}}</h4>
                          <div class="card-text-news"><small>{{ dateFormat(viewData.posts[3]['Content']['publish_date']) }}</small></div>
                          
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
           <div class="row my-5">
              <div class="col text-center">
                <router-link :to="{ name: 'postList' }" class="btn btn-outline-primary">Ver mais</router-link>
              </div>
            </div> -->
            <!--
            <BaseSpinner v-if="loading"/>
          </div>
        </div>
      </div> -->

      <div data-anchor="noticias" class="section" v-if="loading || viewData.posts.length">
        <div class="row mx-0 min-vh-100">
          <div class="col news">
            <h2 class="h1 mb-11">Notícias</h2>
            <div>
        <light-widget></light-widget>
      </div>
            <BaseSpinner v-if="loading"/>
          </div>
        </div>
      </div>

      <div data-anchor="regulamento" class="">
        <div class="rules">
          <div class="row mb-11">
            <div class="rules-content col-lg-7">
              <h2 class="h1 mb-11">Regulamento</h2>
              <!-- <div role="tablist" class="accordion faq">
                <b-card no-body class="faq-card" v-for="question in faq_questions" :key="question.key">
                  <b-card-header header-tag="header" class="faq-card-header" role="tab">
                    <b-link class="faq-card-header-button" href="#" v-b-toggle="'accordion'+question.key">
                      <div class="faq-card-header-title">{{question.question}}</div>
                      <div class="faq-card-header-addon"><span class="fas fa-fw fa-arrow-circle-down"></span></div>
                    </b-link>
                  </b-card-header>
                  <b-collapse class="faq-card-collapse" :id="'accordion'+question.key" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text>{{question.answer}}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div> -->
            </div>
          </div>
          <div class="row">
            <div class="rules-buttons col">
              <div class="vertical-buttons">
               <router-link class="d-block btn btn-outline-primary" :to="{ name: 'regulation' }">Regulamento completo</router-link>
                <a v-if="customData.rules.rules_file" class="btn btn-outline-primary d-block" :href="customData.rules.rules_file" target="_blank">Download</a>
              </div>
             
            </div>
          </div>
        </div>
      </div>



      <div data-anchor="footer" class="section fp-auto-height">
        <div class="row mx-0">
          <div class="col px-0">
            <footer class="footer">
              <div class="sponsors">
                <div class="sponsor">
                  <figure class="figure">
                    <a href="" target="_blank">
                      <img class="sponsor-img" src="@/assets/images/arte-lei-de-incentivo-a-cultura.png" alt="Arte da Lei de Incentivo à Cultura">
                    </a>
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption align-self-start">Patrocínio</figcaption>
                    <div class="mx-n4">
                      <a href="//www.3m.com.br/" target="_blank">
                        <img class="sponsor-img" src="@/assets/images/logo-3m.png" alt="Logo da 3M">
                      </a>
                      <a href="//sebrae.com.br/" target="_blank">
                        <img class="sponsor-img" src="@/assets/images/logo-sebrae.png" alt="Sebrae">
                      </a>
                    </div>
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption align-self-start">Apoio</figcaption>
                    <a href="//www.progen.com.br/" target="_blank">
                      <img class="sponsor-img" src="@/assets/images/logo-progen-white.png" alt="Logo do Mercado Livre">
                    </a>
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption" style="margin-bottom: 0px;">Organização</figcaption>
                    <a href="//elo3.com.br/" target="_blank">
                      <img class="sponsor-img px-4" src="@/assets/images/logo_elo3_rosa.png" alt="Logo elo 3">
                    </a>
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption align-self-start" style="margin-bottom: 0px;">Realização</figcaption>
                    <div class="mx-n4">
                      <a href="//pt.wikipedia.org/wiki/Organiza%C3%A7%C3%A3o_Mundial_da_Criatividade" target="_blank">
                        <img class="sponsor-img" src="@/assets/images/logo-wco-white.png" alt="World Creativity Organization">
                      </a>
                      <a href="//www.gov.br/turismo/pt-br" target="_blank">
                        <img class="sponsor-img" src="@/assets/images/secretaria-e-turismo.png" alt="Arte Pátria Amada Brasil">
                      </a>
                    </div>
                  </figure>
                </div>
              </div>


              <!-- <div class="sponsors">
                <div class="sponsor">
                  <figure class="figure">
                    <img class="sponsor-img" src="@/assets/images/arte-lei-de-incentivo-a-cultura.png" alt="Arte da Lei de Incentivo à Cultura">
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption">Patrocínio:</figcaption>
                    <img class="sponsor-img" src="@/assets/images/logo-3m.png" alt="Logo da 3M">
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption">Apoio:</figcaption>
                    <img class="sponsor-img" src="@/assets/images/logo-progen-white.png" alt="Logo Progen">
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption">Organização:</figcaption>
                    <img class="sponsor-img" src="@/assets/images/logo-elo-3.svg" alt="Logo elo 3">
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption">Parcerias:</figcaption>
                    <div class="mx-n4">
                      <img class="sponsor-img px-4" src="@/assets/images/logo-razoes-para-acreditar.png" alt="Logo do Razões para Acreditar">
                      <img class="sponsor-img px-4" src="@/assets/images/logo-voaa.png" alt="Logo do Voaa">
                      <img class="sponsor-img px-4" src="@/assets/images/logo-zupi-co.png" alt="Logo do Zupi.co">
                    </div>
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption align-self-start" style="margin-bottom: 0px;">Realização:</figcaption>
                    <img class="sponsor-img w-100" src="@/assets/images/arte-patria-amada-brasil.png" alt="Arte Pátria Amada Brasil">
                  </figure>
                </div>
              </div> -->
            </footer>
          </div>
        </div>
      </div>
    </full-page>
</template>

<script>
  //import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true (https://github.com/alvarotrigo/vue-fullpage.js)
  // import { loadDateProgressbar } from '../assets/scripts/date_progressbar'
  import { getServerURL } from "../const";
  import LightWidget from '@/components/LightWidget.vue';
  import Vue from 'vue';
  import VueCountdown from '@chenfengyuan/vue-countdown';
  import axios from 'axios'
  import VueFullPage from 'vue-fullpage.js'
  import moment from 'moment'
  import BaseSpinner from '@/components/BaseSpinner'
  import DateProgressBar from '@/components/DateProgressBar'
  import TheHeroMobile from '@/components/TheHeroMobile'
  import Curators from '@/components/Curators'
  import Mobilizers from '@/components/Mobilizers'
  // import Community from '@/components/Community'

  Vue.use(VueFullPage);
  Vue.component(VueCountdown.name, VueCountdown);

  export default {
    name: 'home',
    components: {
      BaseSpinner,
      DateProgressBar,
      TheHeroMobile,
      Curators,
      Mobilizers,
      LightWidget,
      // Community,
    },
    props: {
      login: Boolean,
    },
    data() {
      return {
        customData: { 'general':{}, 'home':{}, 'rules':{}, 'stages':{} },
        viewData: { 'posts':[] },
        loading: false,
        errored: false,
        mobilizerSlug: this.$route.params.mobilizerSlug || null,
        curatorSlug: this.$route.params.curatorSlug || null,
        showLogin: this.$route.name === 'login' ? true : this.login || false,
        showActivateCuratorProfile: this.$route.name === 'activateCuratorProfile',
        fullpageOptions: {
          animateAnchor: false,
          autoScrolling: false,
          navigation: false,
          responsiveWidth: 0,
          responsiveHeight: 0
        },
        fullpageFinalOptions: {
          animateAnchor: false,
          autoScrolling: true,
          navigation: true,
          responsiveWidth: 1200,
          responsiveHeight: 720
        },
      }
    },
    mounted () {
      this.loading = true;
      axios.get(getServerURL('/home'))
        .then(response => {
          this.customData = response.data.responseData.viewConfig;
          this.viewData = response.data.responseData;
          this.fullpageOptions = this.fullpageFinalOptions;
          this.$refs.dateProgress.loadData(this.customData.stages.stage_dates);
        })
        .catch(() => {
          // console.error(error);
          this.errored = true
        })
        .finally(() => {
          this.loading = false;
        });
    },
    computed: {
      faq_questions(){
        try { return JSON.parse(this.customData.rules.questions) } catch(e){ return [] }
      }
    },
    methods: {
      dateFormat(dateStr) {
        return moment(dateStr).format('DD/MM/YYYY HH:mm')   // e.g. 24/05/2019 10:15
      },
      safe: value => value || {},
      getTime: date => (new Date(date) - new Date())
    }
  }
</script>

<style src="fullpage.js/dist/fullpage.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>


.card-img-overlay-hover {
  color: transparent;
  transition: color .15s ease-in-out;

  &:hover {
    color: inherit;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.category {
  height: 80vh;
  -webkit-overflow-scrolling: touch;
}

.sub-category {
  height: 66vh;

  @media (min-width: 768px) {
    height: 70vh;
  }
}

.category-img {
  height: 100%;
  overflow: hidden;
}

::-webkit-scrollbar {
  @media (min-width: 768px) {
    height: 12px;
    background: #FFF;
    border-radius: 10px;

    &-thumb {
      background: #000;
      border: 1px solid #FFF;
      border-radius: 10px;
    }
  }
}

.fullscreen-bg {
  position: relative;
  z-index: 0;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  z-index: -100;
}


</style>
