<template>
  <div>
    <b-jumbotron class="mb-0" bg-variant="dark" fluid>
      <b-navbar type="dark" class="px-1">
        <b-navbar-brand :to="{ name: 'home' }" class="btn btn-link">
          <i class="fas fa-long-arrow-alt-left"></i> Voltar
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <div class="ml-auto">
            <b-button variant="link" class="ml-7" @click="handleLogout"><i class="fas fa-sign-out-alt"></i> Logout</b-button>
          </div>
        </b-collapse>
      </b-navbar>
      <b-row>
        <b-col>
          <div class="jumbotron-text">
            <!-- <img class="border mb-7" :src="user.avatarUrl" width="190" height="190" alt="..." /> -->
            <img-inputer
              ref="userAvatarInput"
              class="border mb-7"
              :class="{ 'border-success': uploadSuccess, 'border-danger': uploadFailed || getUserErrorMessage }"
              :placeholder="getUserErrorMessage ? 'Edição de perfil não disponível no momento' : 'Adicionar foto de perfil (recomendado: 190x190)'"
              bottom-text="Arraste e solte aqui ou clique para alterar (recom.: 190x190)"
              exceed-size-text="Tamanho do arquivo deve ser menor que "
              :max-size="2048"
              :img-src="user.avatarUrl || user.avatarUrlPublic"
              v-model="user.avatarFile"
              accept="image/*,image/heic"
              @onChange="handleAvatarUpload"
              no-hover-effect
            />
            <h1 class="mb-7">{{ user.name }}</h1>
            <div class="d-flex flex-column">
              <button
                class="btn btn-outline-primary mb-3"
                :disabled="loadingUser || getUserErrorMessage || showProject || showCreateProject"
                v-show="!showEditProfile"
                @click="showEditProfile = true"
              >Editar perfil</button>
              <button
                class="btn btn-outline-primary mb-3"
                v-show="showEditProfile"
                @click="showEditProfile = false; wasProfileValidated = false;"
              >Voltar</button>
              <button
                class="btn btn-outline-primary mb-3"
                :disabled="loadingProjects || getProjectsErrorMessage || getCategoriesErrorMessage || showProject || showEditProfile"
                v-if="!isCurator"
                v-show="!showCreateProject"
                @click="showCreateProject = true"
                :title="unsentProjects.length ? 'Continue de onde parou ao cadastrar seu último projeto.' : ''"
              >
                <span v-show="!unsentProjects.length">Cadastrar projeto</span>
                <span v-show="unsentProjects.length">Retomar cadastro</span>
                <span
                  class="ml-3 badge badge-pill badge-danger"
                  v-show="unsentProjects.length"
                >!</span>
              </button>
              <a
                class="btn btn-outline-primary"
                :disabled="loadingProjects || getProjectsErrorMessage || getCategoriesErrorMessage || showProject || showEditProfile"
                v-if="!isCurator"
                v-show="!showCreateProject"
                href="//www.startvc.com.br" target="_blank"
                :title="unsentProjects.length ? 'Continue de onde parou ao cadastrar seu último projeto.' : ''"
              >
                <span>Cadastrar categoria Startup</span>
              </a>
              <button
                class="btn btn-outline-primary"
                v-show="showCreateProject"
                @click="showCreateProject = false"
              >Voltar</button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-jumbotron>
    <div class="UserDashboardMain">
      <div class="container">
        <div class="row pt-11 pb-11">
          <div class="col-md-10 offset-md-1">
            <BaseSpinner class="mb-11" v-show="loadingUser || loadingProjects || loadingPosts || loadingProjectCriterias || loadingProjectRatings"/>
            <b-alert fade dismissible variant="danger" class="mb-11" v-model="showGetUserErrorMessage">
              <h5 class="h5">Erro!</h5>
              <p class="mb-0"><strong>Edição de perfil não disponível no momento. Tente novamente mais tarde.</strong></p>
              <p class="mb-0">Motivo: {{ getUserErrorMessage }}</p>
            </b-alert>
            <b-alert fade dismissible variant="danger" class="mb-11" v-model="showGetProjectsErrorMessage">
              <h5 class="h5">Erro!</h5>
              <p class="mb-0"><strong>Listagem de projetos não disponível no momento. Tente novamente mais tarde.</strong></p>
              <p class="mb-0">Motivo: {{ getProjectsErrorMessage }}</p>
            </b-alert>
            <b-alert fade dismissible variant="danger" class="mb-11" v-model="showGetCategoriesErrorMessage">
              <h5 class="h5">Erro!</h5>
              <p class="mb-0"><strong>Cadastro de projetos não disponível no momento. Tente novamente mais tarde.</strong></p>
              <p class="mb-0">Motivo: {{ getCategoriesErrorMessage }}</p>
            </b-alert>
            <b-alert fade dismissible variant="danger" class="mb-11" v-model="showGetProjectCriteriasRatingsErrorMessage">
              <h5 class="h5">Erro!</h5>
              <p class="mb-0"><strong>Avaliação de projetos não disponível no momento. Tente novamente mais tarde.</strong></p>
              <p class="mb-0">Motivo: {{ getProjectCriteriasRatingsErrorMessage }}</p>
            </b-alert>
            <b-alert fade dismissible variant="danger" class="mb-11" v-model="showGetPostsErrorMessage">
              <h5 class="h5">Erro!</h5>
              <p class="mb-0"><strong>Listagem de notícias não disponível no momento. Tente novamente mais tarde.</strong></p>
              <p class="mb-0">Motivo: {{ getPostsErrorMessage }}</p>
            </b-alert>
            <div v-show="!showEditProfile && !showCreateProject && !showProject">
              <ProjectList :projects="projects" :categories="categories" :statusList="statusList" :empty-title="projectListEmptyTitle" :empty-message="projectListEmptyMessage" @itemClicked="showProjectDetail" v-if="!loadingProjects && !getProjectsErrorMessage"/>
              <div class="posts" v-if="posts.length">
                <h3 class="h3 mb-7">Notícias do Prêmio Brasil Criativo</h3>
                <div class="row mb-7">
                  <div class="col">
                    <div class="card card-horizontal mb-7" v-if="posts[0]">
                      <router-link :to="{ name: 'postDetail', params: { postSlug: posts[0].Content.slug } }">
                        <img
                          class="card-img card-img-translucid"
                          :src="posts[0].FeaturedImage.croppedImg.url"
                          alt="Capa da notícia"
                        />
                        <div class="card-img-overlay">
                          <h3 class="card-title">{{ posts[0].Content.title }}</h3>
                          <div class="card-text">
                            <small>{{ posts[0].Content.publish_date }}</small>
                          </div>
                        </div>
                      </router-link>
                    </div>
                    <div class="card card-horizontal mb-7" v-if="posts[1]">
                      <router-link :to="{ name: 'postDetail', params: { postSlug: posts[1].Content.slug } }">
                        <img
                          class="card-img card-img-translucid"
                          :src="posts[1].FeaturedImage.croppedImg.url"
                          alt="Capa da notícia"
                        />
                        <div class="card-img-overlay">
                          <h3 class="card-title">{{ posts[1].Content.title }}</h3>
                          <div class="card-text">
                            <small>{{ posts[1].Content.publish_date }}</small>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="d-flex justify-content-center">
                      <router-link :to="{ name: 'postList' }" class="btn btn-outline-primary">Ver mais</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="showEditProfile">
              <UserProfileCurator v-if="isCurator" />
              <template v-else>
              <form
                novalidate
                :class="{ 'was-validated': wasProfileValidated }"
                @submit.prevent="handleSubmit"
              >
                <b-alert variant="danger" dismissible fade v-model="showErrorMessage">
                  <h5 class="h5">Erro!</h5>
                  <p class="mb-0">{{ errorMessage }}</p>
                </b-alert>
                <b-alert variant="danger" dismissible fade v-model="showErrors">
                  <h5 class="h5">Erro!</h5>
                  <ul class="list-unstyled mb-0">
                    <li v-for="(error, index) in errors" :key="index">
                      <div v-for="(errorMsg, index2) in error" :key="index2">{{ errorMsg }}</div>
                    </li>
                  </ul>
                </b-alert>
                <b-alert variant="success" dismissible fade v-model="showSuccess">
                  <h5 class="h5">Sucesso!</h5>
                  <p class="mb-0">Perfil atualizado com sucesso!</p>
                </b-alert>
                <div class="form-group">
                  <label for="nome" class="sr-only">nome completo</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="nome completo"
                    id="nome"
                    v-model="user.name"
                    minlength="5"
                    required
                    @keyup="handleKeyUp"
                  />
                  <div class="invalid-feedback">Obrigatório (mín. 10 caracteres)</div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm">
                    <label for="email" class="sr-only">email</label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="email"
                      id="email"
                      v-model="user.email"
                      minlength="8"
                      required
                    />
                    <div class="invalid-feedback">Obrigatório (mín. 8 caracteres)</div>
                  </div>
                  <div class="form-group col-sm">
                    <label for="senha" class="sr-only">senha</label>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="senha"
                      id="senha"
                      v-model="user.password"
                      minlength="8"
                      required
                    />
                    <div class="invalid-feedback">Obrigatório (mín. 8 caracteres)</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="sobre" class="sr-only">fale sobre você</label>
                  <textarea
                    rows="10"
                    class="form-control"
                    placeholder="fale sobre você"
                    aria-describedby="ajudaSobre"
                    id="sobre"
                    v-model="user.about"
                    minlength="8"
                    maxlength="300"
                    required
                  ></textarea>
                  <small id="ajudaSobre" class="form-text">Máximo de 300 caracteres</small>
                  <div class="invalid-feedback">Obrigatório (mín. 8 caracteres)</div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm">
                    <label for="cpf" class="sr-only">cpf</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="cpf"
                      id="cpf"
                      v-model="user.document"
                      minlength="11"
                      maxlength="11"
                      required
                      :class="{ 'border-danger': userDocumentInvalid, 'icon-is-invalid': userDocumentInvalid, 'is-invalid': userDocumentInvalid, 'border-success': userDocumentValid, 'icon-is-valid': userDocumentValid }"
                      @keyup="showUserDocumentValidity"
                    />
                    <div class="invalid-feedback">Obrigatório (somente documento válido)</div>
                  </div>
                  <!-- <div class="form-group col-sm">
                    <label for="rg" class="sr-only">rg</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="rg"
                      id="rg"
                      v-model="user.document_rg"
                      maxlength="13"
                    />
                    <div class="invalid-feedback">Obrigatório (somente números)</div>
                  </div> -->
                  <div class="form-group col-sm">
                    <label for="celular" class="sr-only">celular com ddd</label>
                    <input
                      type="tel"
                      class="form-control"
                      placeholder="celular com ddd"
                      id="celular"
                      v-model="user.cellphone"
                      minlength="10"
                      required
                    />
                    <div class="invalid-feedback">Obrigatório (mín. 10 caracteres)</div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm">
                    <label for="cidade" class="sr-only">cidade</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="cidade"
                      id="cidade"
                      v-model="user.city_name"
                      required
                    />
                    <div class="invalid-feedback">Obrigatório</div>
                  </div>
                  <div class="form-group col-sm">
                    <label for="estado" class="sr-only">estado</label>
                    <select class="custom-select" id="estado" v-model="user.state_name" required>
                      <option value="AC">AC</option>
                      <option value="AL">AL</option>
                      <option value="AM">AM</option>
                      <option value="AP">AP</option>
                      <option value="BA">BA</option>
                      <option value="CE">CE</option>
                      <option value="DF">DF</option>
                      <option value="ES">ES</option>
                      <option value="GO">GO</option>
                      <option value="MA">MA</option>
                      <option value="MG">MG</option>
                      <option value="MS">MS</option>
                      <option value="MT">MT</option>
                      <option value="PA">PA</option>
                      <option value="PB">PB</option>
                      <option value="PE">PE</option>
                      <option value="PI">PI</option>
                      <option value="PR">PR</option>
                      <option value="RJ">RJ</option>
                      <option value="RN">RN</option>
                      <option value="RO">RO</option>
                      <option value="RR">RR</option>
                      <option value="RS">RS</option>
                      <option value="SC">SC</option>
                      <option value="SE">SE</option>
                      <option value="SP">SP</option>
                      <option value="TO">TO</option>
                    </select>
                    <div class="invalid-feedback">Obrigatório</div>
                  </div>
                  <div class="form-group col-sm">
                    <label for="cep" class="sr-only">cep</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="cep"
                      id="cep"
                      v-model="user.zipcode"
                      minlength="8"
                      maxlength="8"
                      required
                    />
                    <div class="invalid-feedback">Obrigatório (somente números)</div>
                  </div>
                </div>
                <!-- <div class="form-group">
                  <label for="endereco" class="sr-only">endereço residencial completo</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="endereço residencial completo"
                    id="endereco"
                    v-model="user.address"
                    minlength="8"
                    required
                  />
                  <div class="invalid-feedback">Obrigatório (mín. 8 caracteres)</div>
                </div> -->
                <div class="form-row">
                  <div class="form-group col-sm">
                    <label for="twitter" class="sr-only">twitter</label>
                    <input
                      type="url"
                      class="form-control"
                      placeholder="https://twitter.com/seu-perfil"
                      aria-describedby="ajudaTwitter"
                      id="twitter"
                      v-model="user.twitter"
                    />
                    <small id="ajudaTwitter" class="form-text">Não obrigatório</small>
                  </div>
                  <div class="form-group col-sm">
                    <label for="facebook" class="sr-only">facebook</label>
                    <input
                      type="url"
                      class="form-control"
                      placeholder="https://facebook.com/seu-perfil"
                      aria-describedby="ajudaFacebook"
                      id="facebook"
                      v-model="user.facebook"
                    />
                    <small id="ajudaFacebook" class="form-text">Não obrigatório</small>
                  </div>
                  <div class="form-group col-sm">
                    <label for="instagram" class="sr-only">instagram</label>
                    <input
                      type="url"
                      class="form-control"
                      placeholder="https://instagram.com/seu-perfil"
                      aria-describedby="ajudaInstagram"
                      id="instagram"
                      v-model="user.instagram"
                    />
                    <small id="ajudaInstagram" class="form-text">Não obrigatório</small>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-6">
                    <label for="racial" class="sr-only">Identificação racial</label>
                    <select class="custom-select" id="racial" v-model="user.racial" required>
                      <option value disabled>identificação racial</option>
                      <option value="branco">Branco</option>
                      <option value="preto">Preto</option>
                      <option value="pardo">Pardo</option>
                      <option value="amarelo">Amarelo</option>
                      <option value="indigena">Indígena</option>
                      <option value="outros">Outros</option>
                    </select>
                    <div class="invalid-feedback">Obrigatório</div>
                  </div>
                  <div class="form-group col-sm-6">
                    <label for="genero" class="sr-only">Identificação de gênero</label>
                    <select class="custom-select" id="genero" v-model="user.gender" required>
                      <option value disabled>identificação de gênero</option>
                      <option value="mulher-cis">Mulher-cis</option>
                      <option value="homem-cis">Homem-cis</option>
                      <option value="homem-trans">Homem-trans</option>
                      <option value="mulher-trans">Mulher-trans</option>
                      <option value="travesti">Travesti</option>
                      <option value="nao-binario">Não-binário</option>
                      <option value="outros">Outros</option>
                    </select>
                    <div class="invalid-feedback">Obrigatório</div>
                  </div>
                </div>
                <!-- <div class="form-group">
                  <label for="areasDeInteresse" class="sr-only">áreas de interesse</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="áreas de interesse"
                    aria-describedby="ajudaAreasDeInteresse"
                    id="areasDeInteresse"
                    v-model="user.interests"
                  />
                  <small id="ajudaAreasDeInteresse" class="form-text">Não obrigatório</small>
                </div> -->
                <!-- <fieldset class="form-group">
                  <div class="form-row align-items-center">
                    <legend
                      class="col-form-label col-sm-auto font-weight-bolder"
                    >Deseja fazer parte da rede Project Hub?</legend>
                    <div class="col-sm-auto">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="joinedProjectNetwork"
                          id="joinedProjectNetworkRadio0"
                          value="0"
                          v-model="user.joinedProjectNetwork"
                          required
                        />
                        <label class="form-check-label" for="joinedProjectNetworkRadio0">Não</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="joinedProjectNetwork"
                          id="joinedProjectNetworkRadio1"
                          value="1"
                          v-model="user.joinedProjectNetwork"
                          required
                        />
                        <label class="form-check-label" for="joinedProjectNetworkRadio1">Sim</label>
                      </div>
                    </div>
                  </div>
                </fieldset> -->
                <div class="d-flex justify-content-center mb-10 mb-md-11">
                  <input type="submit" class="btn btn-outline-primary ml-sm-3" value="Salvar" />
                </div>
              </form>
              </template>
            </div>
            <div v-if="!isCurator && !getProjectsErrorMessage && !getCategoriesErrorMessage" v-show="showCreateProject">
              <b-row>
                <b-col>
                  <div v-show="!projectSubmitSuccessFinal">
                    <h3 class="h3 mb-9 text-center text-lg-left">Cadastre seu projeto</h3>
                    <b-row class="mb-9">
                      <b-col lg="2" class="d-flex justify-content-center mb-3 mb-lg-0">
                        <div class="pr-3 font-weight-bolder display-4">
                          <span
                            v-show="!projectSubmitSuccess1 && !projectSubmitSuccess2 && !projectSubmitSuccess3 && !projectSubmitSuccess4"
                          >0%</span>
                          <span
                            v-show=" projectSubmitSuccess1 && !projectSubmitSuccess2 && !projectSubmitSuccess3 && !projectSubmitSuccess4"
                          >25%</span>
                          <span
                            v-show=" projectSubmitSuccess1 &&  projectSubmitSuccess2 && !projectSubmitSuccess3 && !projectSubmitSuccess4"
                          >50%</span>
                          <span
                            v-show=" projectSubmitSuccess1 &&  projectSubmitSuccess2 &&  projectSubmitSuccess3 && !projectSubmitSuccess4"
                          >75%</span>
                          <span
                            v-show=" projectSubmitSuccess1 &&  projectSubmitSuccess2 &&  projectSubmitSuccess3 &&  projectSubmitSuccess4"
                          >100%</span>
                        </div>
                      </b-col>
                      <b-col lg="10">
                        <div
                          class="mb-3"
                        >Complete seu projeto, lembre-se que o projeto só estará participando quando todas as informacões forem preenchidas corretamente.</div>
                        <div class="text-success">
                          <i class="fas fa-clock mr-1"></i> Duração estimada: 9 minutos
                        </div>
                      </b-col>
                    </b-row>
                    <b-nav class="mb-9 mx-n7 justify-content-center justify-content-lg-start">
                      <b-nav-item
                        class="h5 text-uppercase"
                        @click="showProjectForm1 = true; showProjectForm2 = false; showProjectForm3 = false; showProjectForm4 = false;"
                      >
                        <div
                          class="progressive-form-nav-item"
                          :class="{ 'active': showProjectForm1 }"
                        >
                          Dados do projeto
                          <i
                            class="fas fa-check-circle text-success"
                            v-show="projectSubmitSuccess1"
                          ></i>
                        </div>
                      </b-nav-item>
                      <b-nav-item
                        class="h5 text-uppercase"
                        :disabled="!projectSubmitSuccess1"
                        @click="showProjectForm1 = false; showProjectForm2 = true; showProjectForm3 = false; showProjectForm4 = false;"
                      >
                        <div
                          class="progressive-form-nav-item"
                          :class="{ 'active': showProjectForm2 }"
                        >
                          Apresente seu projeto
                          <i
                            class="fas fa-check-circle text-success"
                            v-show="projectSubmitSuccess2"
                          ></i>
                        </div>
                      </b-nav-item>
                      <b-nav-item
                        class="h5 text-uppercase"
                        :disabled="!projectSubmitSuccess2"
                        @click="showProjectForm1 = false; showProjectForm2 = false; showProjectForm3 = true; showProjectForm4 = false;"
                      >
                        <div
                          class="progressive-form-nav-item"
                          :class="{ 'active': showProjectForm3 }"
                        >
                          Redes sociais e sites
                          <i
                            class="fas fa-check-circle text-success"
                            v-show="projectSubmitSuccess3"
                          ></i>
                        </div>
                      </b-nav-item>
                      <b-nav-item
                        class="h5 text-uppercase"
                        :disabled="!projectSubmitSuccess3"
                        @click="showProjectForm1 = false; showProjectForm2 = false; showProjectForm3 = false; showProjectForm4 = true;"
                      >
                        <div
                          class="progressive-form-nav-item"
                          :class="{ 'active': showProjectForm4 }"
                        >
                          Informações adicionais
                          <i
                            class="fas fa-check-circle text-success"
                            v-show="projectSubmitSuccess4"
                          ></i>
                        </div>
                      </b-nav-item>
                    </b-nav>
                    <b-row>
                      <b-col>
                        <form
                          novalidate
                          :class="{ 'was-validated': wasProjectFormValidated1 }"
                          @submit.prevent="handleProjectFormSubmit1"
                          v-show="showProjectForm1"
                        >
                          <b-form-row>
                            <div
                              class="form-group col-sm-5 col-xl-4 d-flex flex-column align-items-center align-items-sm-start mb-9 mb-sm-7"
                            >
                              <label for="projectCoverImageFile" class="h5">
                                Capa do projeto
                                <i
                                  class="fas fa-check text-success"
                                  v-show="projectCoverImageFileChosen"
                                ></i>
                                <i
                                  class="fas fa-times text-danger"
                                  v-show="projectCoverImageFileNotChosen"
                                ></i>
                              </label>
                              <img-inputer
                                class="border"
                                placeholder="Adicionar capa do projeto (máx. 2 MB)"
                                bottom-text="Arraste e solte aqui ou clique para alterar (máx. 2 MB)"
                                exceed-size-text="Tamanho do arquivo deve ser menor que "
                                :max-size="2048"
                                :img-src="project.coverImageUrl"
                                v-model="project.coverImageFile"
                                no-hover-effect
                                @onChange="showProjectCoverImageValidity"
                              />
                              <div class="invalid-feedback">Obrigatório</div>
                            </div>
                            <div
                              class="form-row col-sm-7 col-xl-8 d-flex flex-column justify-content-center"
                            >
                              <div
                                class="form-group d-flex flex-column"
                                v-if="project.ownerDocumentUrl"
                              >
                                <label for="projectOwnerDocumentUrl" class="h5">Arquivo atual</label>
                                <a :href="project.ownerDocumentUrl" target="_blank"><i class="fas fa-paperclip"></i> Ver arquivo enviado</a>
                              </div>
                              
                            <!-- <div class="form-row"> -->

                              <div class="form-group col-sm-6">
                                <label for="projectCategory" class="h5">Categoria</label>
                                <select class="custom-select" id="projectCategory" v-model="project.categoryId" required>
                                  <option value disabled>Categoria</option>
                                  <option value="77">Música</option>
                                  <option value="78">Artesanato</option>
                                  <option value="79">Arte Urbana</option>
                                  <option value="80">Tecnologia</option>
                                  <option value="81">Criadores de Conteúdo</option>
                                  <option value="82">Audiovisual</option>
                                  <option value="83">Game</option>
                                  <option value="84">Publicidade</option>
                                  <option value="85">Arquitetura</option>
                                  <option value="86">Design</option>
                                  <option value="87">Moda</option>
                                  <option value="88">Gastronomia</option>
                                  <option value="89">Lideranças Criativas</option>
                                  <option value="90">Comunidades Criativas</option>
                                  <option value="91">Territórios Criativos</option>
                                </select>
                                <div class="invalid-feedback">Obrigatório</div>
                              </div>
                            <!-- </div> -->
                              <!-- <div class="form-group">
                                <label for="projectOwnerDocumentFile" class="h5">Cartão CNPJ ou comprovante de CPF</label>
                                <b-form-file
                                  id="projectOwnerDocumentFile"
                                  v-model="project.ownerDocumentFile"
                                  aria-describedby="projectOwnerDocumentFileHelp"
                                  accept="image/*,application/pdf"
                                  :required="!project.ownerDocumentUrl"
                                  plain
                                ></b-form-file>
                                <small
                                  id="projectOwnerDocumentFileHelp"
                                  class="form-text"
                                >Faça o upload de um arquivo de alta resolução</small>
                                <div class="invalid-feedback">Obrigatório</div>
                              </div> -->
                            </div>
                          </b-form-row>
                          <b-form-row>
                            <div class="form-group col-sm-7 col-lg-8">
                              <label for="projectName" class="h5">Nome do projeto</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="nome do projeto"
                                id="projectName"
                                v-model="project.name"
                                required
                              />
                              <div class="invalid-feedback">Obrigatório</div>
                            </div>
                            <div class="form-group col-sm-5 col-lg-4">
                              <label for="projectCreationDate" class="h5">Data de criação</label>
                              <input
                                type="date"
                                class="form-control"
                                placeholder="data de criação"
                                id="projectCreationDate"
                                v-model="project.start_date"
                                required
                              />
                              <div class="invalid-feedback">Obrigatório</div>
                            </div>
                          </b-form-row>
                          <fieldset>
                            <legend class="h5">Local do projeto</legend>
                            <b-form-row>
                              <div class="form-group col">
                                <label for="projectState" class="sr-only">estado</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="estado"
                                  id="projectState"
                                  v-model="project.state_name"
                                  minlength="2"
                                  maxlength="2"
                                  required
                                />
                                <div class="invalid-feedback">Obrigatório (sigla)</div>
                              </div>
                              <div class="form-group col">
                                <label for="projectCity" class="sr-only">cidade</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="cidade"
                                  id="projectCity"
                                  v-model="project.city_name"
                                  required
                                />
                                <div class="invalid-feedback">Obrigatório</div>
                              </div>
                            </b-form-row>
                          </fieldset>
                          <!-- <fieldset class="form-group mb-11"> -->
                            <!-- <legend class="h5">Categoria do projeto</legend>
                            <div
                              class="form-check"
                              v-for="category in categories"
                              :key="category.id"
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="projectCategory"
                                :id="'projectCategory' + category.id"
                                :value="category.id"
                                v-model="project.categoryIds"
                                required
                              />
                              <label
                                class="form-check-label"
                                :for="'projectCategory' + category.id"
                              >{{ category.name }}</label>
                            </div>
                             -->

                          <!-- </fieldset> -->
                          <div class="d-flex flex-column align-items-center">
                            <input
                              type="submit"
                              class="btn btn-outline-primary mb-9"
                              value="Salvar & continuar"
                            />
                          </div>
                        </form>
                        <form
                          novalidate
                          :class="{ 'was-validated': wasProjectFormValidated2 }"
                          @submit.prevent="handleProjectFormSubmit2"
                          v-show="showProjectForm2"
                        >
                          <div class="form-group">
                            <label for="projectObjective" class="sr-only">objetivo do projeto</label>
                            <textarea
                              rows="10"
                              class="form-control"
                              placeholder="objetivo do projeto (Máx. 1000 Caracteres)"
                              aria-describedby="projectObjectiveHelp"
                              id="projectObjective"
                              v-model="project.objective"
                              maxlength="1000"
                              required
                            ></textarea>
                            <div class="invalid-feedback">Obrigatório</div>
                          </div>
                          <div class="form-group">
                            <label for="projectDescription" class="sr-only">apresente seu projeto</label>
                            <textarea
                              rows="10"
                              class="form-control"
                              placeholder="apresente seu projeto (Máx. 1000 Caracteres)"
                              aria-describedby="projectDescriptionHelp"
                              id="projectDescription"
                              v-model="project.presentation"
                              maxlength="1000"
                              required
                            ></textarea>
                            <div class="invalid-feedback">Obrigatório</div>
                          </div>
                          <div class="form-group">
                            <label
                              for="projectSocialImpact"
                              class="sr-only"
                            >descreva o impacto social do projeto</label>
                            <textarea
                              rows="10"
                              class="form-control"
                              placeholder="descreva o impacto social do projeto (Máx. 1000 Caracteres)"
                              aria-describedby="projectSocialImpactHelp"
                              id="projectSocialImpact"
                              v-model="project.social_impact"
                              maxlength="1000"
                              required
                            ></textarea>
                            <div class="invalid-feedback">Obrigatório</div>
                          </div>
                          <div class="form-group mb-9">
                            <label
                              for="projectTargetAudience"
                              class="sr-only"
                            >fale sobre o público-alvo do seu projeto</label>
                            <textarea
                              rows="10"
                              class="form-control"
                              placeholder="fale sobre o público-alvo do seu projeto (Máx. 1000 Caracteres)"
                              aria-describedby="projectTargetAudienceHelp"
                              id="projectTargetAudience"
                              v-model="project.target_audience_text"
                              maxlength="1000"
                              required
                            ></textarea>
                            <div class="invalid-feedback">Obrigatório</div>
                          </div>
                          <div class="d-flex flex-column align-items-center">
                            <input
                              type="submit"
                              class="btn btn-outline-primary mb-9"
                              value="Salvar & continuar"
                            />
                          </div>
                        </form>
                        <form
                          novalidate
                          :class="{ 'was-validated': wasProjectFormValidated3 }"
                          @submit.prevent="handleProjectFormSubmit3"
                          v-show="showProjectForm3"
                        >
                          <div class="form-group">
                            <label for="projectVideoUrl" class="sr-only">link de vídeo</label>
                            <input
                              type="url"
                              class="form-control"
                              placeholder="https://youtube.com/video-do-projeto"
                              aria-describedby="projectVideoUrlHelp"
                              id="projectVideoUrl"
                              v-model="project.video_url"
                            />
                            <small id="projectVideoUrlHelp" class="form-text">Não obrigatório</small>
                            <div class="invalid-feedback">Obrigatório um link válido</div>
                          </div>
                          <div class="form-group">
                            <label for="projectWebsite" class="sr-only">site do projeto</label>
                            <input
                              type="url"
                              class="form-control"
                              placeholder="https://sitedoprojeto.com"
                              aria-describedby="projectWebsiteHelp"
                              id="projectWebsite"
                              v-model="project.site"
                            />
                            <small id="projectWebsiteHelp" class="form-text">Não obrigatório</small>
                            <div class="invalid-feedback">Insira um link válido</div>
                          </div>
                          <!-- <div class="form-row mb-2"> -->
                            <div class="form-group">
                              <label for="projectTwitter" class="sr-only">Link adicional</label>
                              <input
                                type="url"
                                class="form-control"
                                placeholder="Link adicional"
                                aria-describedby="projectTwitterHelp"
                                id="projectTwitter"
                                v-model="project.twitter"
                              />
                              <small id="projectTwitterHelp" class="form-text">Não obrigatório</small>
                              <div class="invalid-feedback">Insira um link válido</div>
                            </div>
                            <!-- <div class="form-group col-sm">
                              <label for="projectFacebook" class="sr-only">facebook</label>
                              <input
                                type="url"
                                class="form-control"
                                placeholder="https://facebook.com/perfil-do-projeto"
                                aria-describedby="projectFacebookHelp"
                                id="projectFacebook"
                                v-model="project.facebook"
                              />
                              <small id="projectFacebookHelp" class="form-text">Não obrigatório</small>
                              <div class="invalid-feedback">Insira um link válido</div>
                            </div>
                            <div class="form-group col-sm">
                              <label for="projectInstagram" class="sr-only">instagram</label>
                              <input
                                type="url"
                                class="form-control"
                                placeholder="https://instagram.com/perfil-do-projeto"
                                aria-describedby="projectInstagramHelp"
                                id="projectInstagram"
                                v-model="project.instagram"
                              />
                              <small id="projectInstagramHelp" class="form-text">Não obrigatório</small>
                              <div class="invalid-feedback">Insira um link válido</div>
                            </div> -->
                          <!-- </div> -->
                          <div class="d-flex flex-column align-items-center">
                            <input
                              type="submit"
                              class="btn btn-outline-primary mb-9"
                              value="Salvar & continuar"
                            />
                          </div>
                        </form>
                        <form
                          novalidate
                          :class="{ 'was-validated': wasProjectFormValidated4 }"
                          @submit.prevent="handleProjectFormSubmit4"
                          v-show="showProjectForm4"
                        >
                          <!-- <div class="form-group d-flex flex-column" v-if="project.picturesUrls">
                            <label for="projectPicturesUrls" class="h5">Arquivo atual</label>
                            <a :href="project.picturesUrls"><i class="fas fa-paperclip"></i> Ver arquivo enviado</a>
                          </div>-->
                          <!-- <div class="form-group">
                            <label
                              for="projectFiles"
                              class="h5"
                            >Enviar arquivo de fotos (zipado)</label>
                            <b-form-file
                              aria-describedby="projectFilesHelp"
                              id="projectFiles"
                              v-model="project.picturesFile"
                              accept=".zip"
                              :required="!project.picturesUrls"
                              plain
                            ></b-form-file>
                            <small id="projectFilesHelp" class="form-text">Máximo 2 MB de arquivo</small>
                            <div class="invalid-feedback">Obrigatório</div>
                          </div>-->
                          <div class="form-group">
                            <label
                              for="projectWhyShouldWin"
                              class="sr-only"
                            >por que seu projeto merece ganhar o Prêmio Brasil Criativo?</label>
                            <textarea
                              rows="10"
                              class="form-control"
                              placeholder="por que seu projeto merece ganhar o Prêmio Brasil Criativo? (Máx. 1000 Caracteres)"
                              aria-describedby="projectWhyShouldWinHelp"
                              id="projectWhyShouldWin"
                              v-model="project.why_should_win"
                              maxlength="1000"
                              required
                            ></textarea>
                            <div class="invalid-feedback">Obrigatório</div>
                          </div>
                          <div class="form-group">
                            <label for="projectFiles" class="h5">Arquivos do projeto</label>
                            <b-form-file
                              aria-describedby="projectFilesHelp"
                              id="projectFiles"
                              v-model="project.picturesFile"
                              plain
                              multiple
                            ></b-form-file>
                            <small id="projectFilesHelp" class="form-text">Máximo 2 MB por arquivo</small>
                            <div class="invalid-feedback">Obrigatório</div>
                          </div>
                          <div class="form-group d-flex flex-column" v-if="!projectFilesRequired">
                            <label for="projectPicturesUrl" class="h5">Arquivos atuais</label>
                            <b-row class="mx-n3 pt-4 pb-n7">
                              <b-col class="picture-preview px-3 pb-7" cols="6" md="4" lg="3" v-for="(pictureUrl, index) in project.picturesUrls" :key="index">
                                <div class="picture-preview__close">
                                  <label class="sr-only" for="btnRemoveFile">remover arquivo</label>
                                  <b-link id="btnRemoveFile" @click="handleBtnRemoveFileClick(pictureUrl)"><i class="far fa-times-circle text-danger"></i></b-link>
                                </div>
                                <a class="picture-preview__link w-100 h-100" :href="pictureUrl" target="_blank">
                                  <template v-if="pictureUrl.match(/(bmp|gif|jpeg|jpg|png|svg|pdf)$/)">
                                    <img class="picture-preview__img w-100 h-100" :src="pictureUrl" />
                                  </template>
                                  <template v-else>
                                    <i class="far fa-file picture-preview__icon"></i>
                                    <div class="picture-preview__name">
                                      {{ pictureUrl.split('/')[pictureUrl.split('/').length - 1] }}
                                    </div>
                                  </template>
                                </a>
                              </b-col>
                            </b-row>
                          </div>
                          <div class="d-flex flex-column align-items-center">
                            <input type="submit" class="btn btn-outline-primary mb-7" value="Salvar" />
                            <p class="text-warning mb-7">ATENÇÃO: Depois de enviado, seu projeto não poderá ser mais editado.</p>
                            <input
                              type="button"
                              class="btn btn-outline-primary mb-7"
                              value="Enviar"
                              :disabled="!projectSubmitSuccess1 || !projectSubmitSuccess2 || !projectSubmitSuccess3 || !projectSubmitSuccess4"
                              @click="handleProjectFormSubmitFinal"
                            />
                          </div>
                        </form>
                        <b-alert
                          variant="danger"
                          dismissible
                          fade
                          v-model="showProjectRequestErrorMessage"
                        >
                          <h5 class="h5">Erro!</h5>
                          <p class="mb-0">{{ projectRequestErrorMessage }}</p>
                        </b-alert>
                        <b-alert variant="danger" dismissible fade v-model="showProjectRequestErrors">
                          <h5 class="h5">Erro!</h5>
                          <ul class="list-unstyled">
                            <li v-for="(error, index) in projectRequestErrors" :key="index">
                              <div v-for="(errorMsg, index2) in error" :key="index2">{{ errorMsg }}</div>
                            </li>
                          </ul>
                        </b-alert>
                      </b-col>
                    </b-row>
                  </div>
                  <div v-show="projectSubmitSuccessFinal">
                    <div class="text-center">
                      <i class="fas fa-7x fa-check-circle text-success mb-11"></i>
                      <h4 class="h4 mb-11">Projeto inscrito com sucesso</h4>
                      <button
                        class="btn btn-outline-primary"
                        @click="resetProjectForm"
                      >Cadastrar novo projeto</button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div v-show="showProject">
              <ProjectDetail :disable-rating="getProjectCriteriasRatingsErrorMessage ? true : false" :project="project" :criterias="projectCriterias" :ratings="projectRatings" @itemDismissed="hideProjectDetail" v-if="!loadingProjectCriterias && !loadingProjectRatings" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import polling from "@/api/services/polling"
import rating from "@/api/services/curator"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ImgInputer from "vue-img-inputer";
import BaseSpinner from "@/components/BaseSpinner";
import UserProfileCurator from "@/components/UserProfileCurator";
import ProjectList from "@/components/ProjectList";
import ProjectDetail from "@/components/ProjectDetail";
import "vue-img-inputer/dist/index.css";

export default {
  name: "UserDashboard",
  // metaInfo() {
  //   return {
  //     title: 'Painel usuário',
  //     meta: []
  //   };
  // },
  components: {
    ImgInputer,
    BaseSpinner,
    UserProfileCurator,
    ProjectList,
    ProjectDetail
  },
  data() {
    return {
      projectListEmptyTitle: "",
      projectListEmptyMessage: "",
      showEditProfile: false,
      showCreateProject: false,
      wasProfileValidated: false,
      showSuccess: false,
      showErrors: false,
      showErrorMessage: false,
      errorMessage: '',
      errors: [],
      uploadSuccess: false,
      uploadFailed: false,
      userDocumentValid: false,
      userDocumentInvalid: false,
      showProjectForm1: true,
      showProjectForm2: false,
      showProjectForm3: false,
      showProjectForm4: false,
      projectCoverImageFileChosen: false,
      projectCoverImageFileNotChosen: false,
      projectFilesRequired: true,
      wasProjectFormValidated1: false,
      wasProjectFormValidated2: false,
      wasProjectFormValidated3: false,
      wasProjectFormValidated4: false,
      projectSubmitSuccess1: false,
      projectSubmitSuccess2: false,
      projectSubmitSuccess3: false,
      projectSubmitSuccess4: false,
      projectSubmitSuccessFinal: false,
      showProjectRequestErrorMessage: false,
      showProjectRequestErrors: false,
      projectRequestErrorMessage: "",
      projectRequestErrors: [],
      showProject: false,
      statusList: [],
      categories: [],
      projectCriterias: [],
      projectRatings: [],
      loading: false,
      loadingProjectCriterias: false,
      loadingProjectRatings: false,
      getUserErrorMessage: "",
      getProjectsErrorMessage: "",
      getCategoriesErrorMessage: "",
      getProjectCriteriasRatingsErrorMessage: "",
      getPostsErrorMessage: "",
      showGetUserErrorMessage: false,
      showGetProjectsErrorMessage: false,
      showGetCategoriesErrorMessage: false,
      showGetProjectCriteriasRatingsErrorMessage: false,
      showGetPostsErrorMessage: false
    };
  },
  computed: {
    ...mapState("user", {
      user: state => state.user,
      loadingUser: state => state.loading
    }),
    ...mapGetters("user", ["isCurator"]),
    ...mapState("project", {
      project: state => state.project,
      projects: state => state.projects,
      loadingProjects: state => state.loading
    }),
    ...mapGetters("project", ["unsentProjects"]),
    ...mapState("category", ["categories.PoolCategory"]),
    ...mapState("curator", ["curators"]),
    ...mapState("post", {
      posts: state => state.posts,
      loadingPosts: state => state.loading
    })
  },
  methods: {
    ...mapActions("user", ["updateUser", "getUser"]),
    ...mapActions("project", ["saveProject", "getAllProjects", "getUserProjects", "removeProjectFile"]),
    ...mapActions("category", ["getCategories"]),
    ...mapActions("post", ["getPosts"]),
    ...mapMutations("project", ["recoverUnsentProject", "setProject", "clearProject"]),
    getProjects() {
      if (this.isCurator) {
        this.getAllProjects()
          .then(() => {
            this.getStatusList();
            this.getCategories();
            if (!this.projects.length) {
              this.projectListEmptyTitle = "Categoria";
              this.projectListEmptyMessage = "Ainda não foram selecionados projetos para sua categoria. 🙈️";
            }
          })
          .catch(({ response: { data: { responseData } } }) => {
            if (responseData.errorMessage) {
              this.getProjectsErrorMessage = responseData.errorMessage;
            } else {
              this.getProjectsErrorMessage = "Erro interno.";
            }
            this.showGetProjectsErrorMessage = true;
          })
          .catch(() => {
            this.getProjectsErrorMessage = "Serviço indisponível.";
            this.showGetProjectsErrorMessage = true;
          });
      } else {
        this.getUserProjects()
          .then(() => {
            if (this.project.registration_step) {
              this.showProjectForm1 = false;
              if (this.project.registration_step == 1) {
                this.showProjectForm1 = true;
              } else if (this.project.registration_step == 2) {
                this.projectSubmitSuccess1 = true;
                this.showProjectForm2 = true;
              } else if (this.project.registration_step == 3) {
                this.projectSubmitSuccess1 = true;
                this.projectSubmitSuccess2 = true;
                this.showProjectForm3 = true;
              } else if (this.project.registration_step == 4) {
                this.projectSubmitSuccess1 = true;
                this.projectSubmitSuccess2 = true;
                this.projectSubmitSuccess3 = true;
                this.showProjectForm4 = true;
              }
            }
            this.checkProjectFilesRequirement();
            this.getStatusList();
            if (!this.projects.length) {
              this.projectListEmptyTitle = "Enviado";
              this.projectListEmptyMessage = "Nenhum projeto enviado. Que tal inscrever o seu? 😁️";
            }
          })
          .catch(({ response: { data: { responseData } } }) => {
            if (responseData.errorMessage) {
              this.getProjectsErrorMessage = responseData.errorMessage;
            } else {
              this.getProjectsErrorMessage = "Erro interno.";
            }
            this.showGetProjectsErrorMessage = true;
          })
          .catch(() => {
            this.getProjectsErrorMessage = "Serviço indisponível.";
            this.showGetProjectsErrorMessage = true;
          });
      }
    },
    getStatusList() {
      // obtencao de todos os status dos projetos
      this.statusList = this.projects.map(project => project.status_name)
      // filtro de status repetidos
      this.statusList = this.statusList.filter((status_name, index, array) => array.indexOf(status_name) === index)
    },
    getCategories() {
      // obtencao de todas categorias dos projetos
      this.categories = this.projects.map(project => project.category_name)
      // filtro de status repetidos
      this.categories = this.categories.filter((category_name, index, array) => array.indexOf(category_name) === index)
      //console.log(this.categories) 
    },
    showUserDocumentValidity() {
      if (this.wasProfileValidated) {
        if (!this.checkUserDocumentValidity(this.user.document)) {
          this.userDocumentValid = false;
          this.userDocumentInvalid = true;
        } else {
          this.userDocumentValid = true;
          this.userDocumentInvalid = false;
        }
      }
    },
    checkUserDocumentValidity(document) {
      const cpf = document.replace(/[^\d]/g, "");

      if (cpf.length !== 11) {
        return false;
      }

      if (
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
      ) {
        return false;
      }

      let s = 0;

      for (let i = 1; i <= 9; i++) {
        s = s + parseInt(cpf[i - 1]) * (11 - i);
      }

      let r = (s * 10) % 11;

      if (r == 10 || r == 11) {
        r = 0;
      }

      if (r != parseInt(cpf[9])) {
        return false;
      }

      s = 0;

      for (let i = 1; i <= 10; i++) {
        s = s + parseInt(cpf[i-1]) * (12 - i);
      }

      r = (s * 10) % 11;

      if ((r == 10) || (r == 11)) {
        r = 0;
      }

      if (r != parseInt(cpf[10])) {
        return false;
      }

      return true;
    },
    handleKeyUp() {
      if (!this.wasProfileValidated) {
        this.wasProfileValidated = true;
      }
    },
    handleSubmit(e) {
      this.wasProfileValidated = true;
      this.showUserDocumentValidity();
      if (e.target.checkValidity() && this.checkUserDocumentValidity(this.user.document)) {
        this.updateUser()
          .then(() => {
            this.showSuccess = true;
          })
          .catch(error => {
            if (error.response.data.responseData.errorMessage) {
              this.errorMessage = error.response.data.responseData.errorMessage;
              this.showErrorMessage = true;
            } else {
              this.errors = error.response.data.responseData.errors;
              this.showErrors = true;
            }
          });
      }
    },
    handleAvatarUpload() {
      this.updateUser()
        .then(() => {
          this.uploadSuccess = true;
          setTimeout(() => {
            this.uploadSuccess = false;
            // this.$refs.userAvatarInput.reset();
          }, 4000);
        })
        .catch(() => {
          this.uploadFailed = true;
          setTimeout(() => {
            this.uploadFailed = false;
            this.user.avatarFile = null;
          }, 4000);
        })
        .finally(() => {
          window.location.href = this.$route.path + "#";
        });
    },
    showProjectCoverImageValidity() {
      if (this.wasProjectFormValidated1) {
        if (!this.project.coverImageUrl && !this.project.coverImageFile) {
          this.projectCoverImageFileChosen = false;
          this.projectCoverImageFileNotChosen = true;
        } else {
          this.projectCoverImageFileNotChosen = false;
          this.projectCoverImageFileChosen = true;
        }
      }
    },
    checkProjectCoverImageValidity() {
      if (
        this.wasProjectFormValidated1 &&
        !this.project.coverImageUrl &&
        !this.project.coverImageFile
      ) {
        return false;
      } else {
        return true;
      }
    },
    checkProjectFilesRequirement() {
      if (this.project.picturesUrls) {
        if (!this.project.picturesUrls.length) {
          this.projectFilesRequired = true;
          return true;
        } else {
          this.projectFilesRequired = false;
          return false;
        }
      }
    },
    handleBtnRemoveFileClick(pictureUrl) {
      this.loading = true;
      const filename = pictureUrl.split('/')[pictureUrl.split('/').length - 1];
      this.removeProjectFile(filename)
        .then(() => {
          if (this.checkProjectFilesRequirement()) {
            this.projectSubmitSuccess4 = false;
          }
        })
        .catch(({ response: { data: { responseData } } }) => {
          if (responseData.errorMessage) {
            this.showProjectRequestErrorMessage = true;
            this.projectRequestErrorMessage = responseData.errorMessage;
          } else {
            this.showProjectRequestErrors = true;
            this.projectRequestErrors = responseData.errors;
          }
          this.projectSubmitSuccess4 = false;
        })
        .catch(() => {
          this.projectRequestErrorMessage = "Serviço indisponível.";
          this.showProjectRequestErrorMessage = true;
          this.projectSubmitSuccess4 = false;
        })
        .finally(() => {
          this.loading = false;
        })
    },
    handleProjectFormSubmit1(e) {
      this.wasProjectFormValidated1 = true;
      this.showProjectCoverImageValidity();
      if (e.target.checkValidity() && this.checkProjectCoverImageValidity()) {
        this.project.registration_step = 2;
        this.saveProject()
          .then(() => {
            this.projectSubmitSuccess1 = true;
            this.showProjectForm1 = false;
            this.showProjectForm2 = true;
          })
          .catch(({ response: { data: { responseData } } }) => {
            if (responseData.errorMessage) {
              this.showProjectRequestErrorMessage = true;
              this.projectRequestErrorMessage = responseData.errorMessage;
            } else {
              this.showProjectRequestErrors = true;
              this.projectRequestErrors = responseData.errors;
            }
            this.projectSubmitSuccess1 = false;
            this.projectSubmitSuccess2 = false;
            this.projectSubmitSuccess3 = false;
            this.projectSubmitSuccess4 = false;
            this.project.registration_step = 1;
          })
          .catch(() => {
            this.projectRequestErrorMessage = "Serviço indisponível.";
            this.showProjectRequestErrorMessage = true;
            this.projectSubmitSuccess1 = false;
            this.projectSubmitSuccess2 = false;
            this.projectSubmitSuccess3 = false;
            this.projectSubmitSuccess4 = false;
            this.project.registration_step = 1;
          });
      } else {
        this.projectSubmitSuccess1 = false;
        this.projectSubmitSuccess2 = false;
        this.projectSubmitSuccess3 = false;
        this.projectSubmitSuccess4 = false;
      }
    },
    handleProjectFormSubmit2(e) {
      this.wasProjectFormValidated2 = true;
      if (e.target.checkValidity()) {
        this.project.registration_step = 3;
        this.saveProject()
          .then(() => {
            this.projectSubmitSuccess2 = true;
            this.showProjectForm2 = false;
            this.showProjectForm3 = true;
          })
          .catch(({ response: { data: { responseData } } }) => {
            if (responseData.errorMessage) {
              this.showProjectRequestErrorMessage = true;
              this.projectRequestErrorMessage = responseData.errorMessage;
            } else {
              this.showProjectRequestErrors = true;
              this.projectRequestErrors = responseData.errors;
            }
            this.projectSubmitSuccess2 = false;
            this.projectSubmitSuccess3 = false;
            this.projectSubmitSuccess4 = false;
            this.project.registration_step = 2;
          })
          .catch(() => {
            this.projectRequestErrorMessage = "Serviço indisponível.";
            this.showProjectRequestErrorMessage = true;
            this.projectSubmitSuccess2 = false;
            this.projectSubmitSuccess3 = false;
            this.projectSubmitSuccess4 = false;
            this.project.registration_step = 2;
          });
      } else {
        this.projectSubmitSuccess2 = false;
        this.projectSubmitSuccess3 = false;
        this.projectSubmitSuccess4 = false;
      }
    },
    handleProjectFormSubmit3(e) {
      this.wasProjectFormValidated3 = true;
      if (e.target.checkValidity()) {
        this.project.registration_step = 4;
        this.saveProject()
          .then(() => {
            this.projectSubmitSuccess3 = true;
            this.showProjectForm3 = false;
            this.showProjectForm4 = true;
          })
          .catch(({ response: { data: { responseData } } }) => {
            if (responseData.errorMessage) {
              this.showProjectRequestErrorMessage = true;
              this.projectRequestErrorMessage = responseData.errorMessage;
            } else {
              this.showProjectRequestErrors = true;
              this.projectRequestErrors = responseData.errors;
            }
            this.projectSubmitSuccess3 = false;
            this.projectSubmitSuccess4 = false;
            this.project.registration_step = 3;
          })
          .catch(() => {
            this.projectRequestErrorMessage = "Serviço indisponível.";
            this.showProjectRequestErrorMessage = true;
            this.projectSubmitSuccess3 = false;
            this.projectSubmitSuccess4 = false;
            this.project.registration_step = 3;
          });
      } else {
        this.projectSubmitSuccess3 = false;
        this.projectSubmitSuccess4 = false;
      }
    },
    handleProjectFormSubmit4(e) {
      this.wasProjectFormValidated4 = true;
      if (e.target.checkValidity()) {
        this.saveProject()
          .then(() => {
            this.projectSubmitSuccess4 = true;
            this.project.picturesFile = null;
            this.checkProjectFilesRequirement();
          })
          .catch(({ response: { data: { responseData } } }) => {
            if (responseData.errorMessage) {
              this.showProjectRequestErrorMessage = true;
              this.projectRequestErrorMessage = responseData.errorMessage;
            } else {
              this.showProjectRequestErrors = true;
              this.projectRequestErrors = responseData.errors;
            }
            this.projectSubmitSuccess4 = false;
          })
          .catch(() => {
            this.projectRequestErrorMessage = "Serviço indisponível.";
            this.showProjectRequestErrorMessage = true;
            this.projectSubmitSuccess4 = false;
          });
      } else {
        this.projectSubmitSuccess4 = false;
      }
    },
    handleProjectFormSubmitFinal() {
      this.loading = true;
      this.saveProject({ send: true })
        .then(() => {
          this.showProjectForm4 = false;
          this.projectSubmitSuccessFinal = true;
          this.getUserProjects()
            .then(() => {
              this.getStatusList();
            });
        })
        .catch(({ response: { data: { responseData } } }) => {
          if (responseData.errorMessage) {
            this.showProjectRequestErrorMessage = true;
            this.projectRequestErrorMessage = responseData.errorMessage;
          } else {
            this.showProjectRequestErrors = true;
            this.projectRequestErrors = responseData.errors;
          }
        })
        .catch(() => {
          this.projectRequestErrorMessage = "Serviço indisponível.";
          this.showProjectRequestErrorMessage = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetProjectForm() {
      this.projectCoverImageFileChosen = false;
      this.projectCoverImageFileNotChosen = false;
      this.showProjectForm1 = true;
      this.showProjectForm2 = false;
      this.showProjectForm3 = false;
      this.showProjectForm4 = false;
      this.wasProjectFormValidated1 = false;
      this.wasProjectFormValidated2 = false;
      this.wasProjectFormValidated3 = false;
      this.wasProjectFormValidated4 = false;
      this.projectSubmitSuccess1 = false;
      this.projectSubmitSuccess2 = false;
      this.projectSubmitSuccess3 = false;
      this.projectSubmitSuccess4 = false;
      this.projectSubmitSuccessFinal = false;
      this.showProjectRequestErrorMessage = false;
      this.showProjectRequestErrors = false;
      this.projectRequestErrorMessage = "";
      this.projectRequestErrors = [];
    },
    showProjectDetail (project) {

      // Set project
      this.setProject(project);

      if (this.isCurator) {

        // Set loadings
        this.loadingProjectCriterias = true;
        this.loadingProjectRatings = true;

        // Get project criterias
        polling.getCriterias(project.id)
          .then(({ data: { responseData } }) => {
            this.projectCriterias = responseData.criterias.map(criteria => {
              return {
                id: criteria.Criteria.id,
                name: criteria.Criteria.name,
                type: criteria.Criteria.type
              };
            });
          })
          .catch(({ response: { data: { responseData } } }) => {
            if (responseData.errorMessage) {
              this.getProjectCriteriasRatingsErrorMessage = responseData.errorMessage;
            } else {
              this.getProjectCriteriasRatingsErrorMessage = "Erro interno.";
            }
            this.showGetProjectCriteriasRatingsErrorMessage = true;
          })
          .catch(() => {
            this.getProjectCriteriasRatingsErrorMessage = "Serviço indisponível.";
            this.showGetProjectCriteriasRatingsErrorMessage = true;
          })
          .finally(() => {
            this.loadingProjectCriterias = false;
          });


        // Get project ratings
        rating.get(project.id)
          .then(({ data: { responseData } }) => {
            this.projectRatings = responseData.ratings;
          })
          .catch(({ response: { data: { responseData } } }) => {
            if (responseData.errorMessage) {
              this.getProjectCriteriasRatingsErrorMessage = responseData.errorMessage;
            } else {
              this.getProjectCriteriasRatingsErrorMessage = "Erro interno.";
            }
            this.showGetProjectCriteriasRatingsErrorMessage = true;
          })
          .catch(() => {
            this.getProjectCriteriasRatingsErrorMessage = "Serviço indisponível.";
            this.showGetProjectCriteriasRatingsErrorMessage = true;
          })
          .finally(() => {
            this.loadingProjectRatings = false;
          });

      }

      this.showProject = true;
    },
    hideProjectDetail () {
      this.showProject = false;
      this.getProjects();
    },
    handleLogout() {
      this.clearProject();
      this.$router.push({ name: 'logout' });
    },
  },
  mounted() {
    this.resetProjectForm();
    this.getUser()
      .catch(({ response: { data: { responseData } } }) => {
        if (responseData.errorMessage) {
          this.getUserErrorMessage = responseData.errorMessage;
        } else {
          this.getUserErrorMessage = "Erro interno.";
        }
        this.showGetUserErrorMessage = true;
      })
      .catch(() => {
        this.getUserErrorMessage = "Serviço indisponível.";
        this.showGetUserErrorMessage = true;
      });
    this.getProjects();
    this.getPosts()
      .catch(({ response: { data: { responseData } } }) => {
        if (responseData.errorMessage) {
          this.getPostsErrorMessage = responseData.errorMessage;
        } else {
          this.getPostsErrorMessage = "Erro interno.";
        }
        this.showGetPostsErrorMessage = true;
      })
      .catch(() => {
        this.getPostsErrorMessage = "Serviço indisponível.";
        this.showGetPostsErrorMessage = true;
      });
    // if (!this.isCurator) {
    //   this.getCategories()
    //     .catch(({ response: { data: { responseData } } }) => {
    //       if (responseData.errorMessage) {
    //         this.getCategoriesErrorMessage = responseData.errorMessage;
    //       } else {
    //         this.getCategoriesErrorMessage = "Erro interno.";
    //       }
    //       this.showGetCategoriesErrorMessage = true;
    //     })
    //     .catch(() => {
    //       this.getCategoriesErrorMessage = "Serviço indisponível.";
    //       this.showGetCategoriesErrorMessage = true;
    //     });
    // }
  },
  metaInfo () {
    return {
      title: "Dashboard"
    }
  }
};
</script>

<style lang="scss">
.UserDashboardMain {
  margin-bottom: 100px;
}

.img-inputer {
  width: 190px;
  height: 190px;
  border-radius: 0;

  img {
    height: 100%;
    object-fit: cover;
  }

  &__preview-box {
    border-radius: 0;
  }

  &__err {
    top: 35%;
    z-index: 2;
    text-align: center;
    padding: 0 0.5rem;
  }
}

.img-inputer--.nhe {
  box-shadow: none;

  &:hover {
    box-shadow: none;
    opacity: 0.7;
  }
}

.progressive-form-nav-item {
  &.active {
    padding-bottom: 0.25rem;
    border-bottom: 3.5px solid #049452;
  }
}

.form-control.icon-is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23F00' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23F00' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.picture-preview {
  height: 190px;
  position: relative;

  &__close {
    display: block;
    position: absolute;
    top: -15px;
    right: -2px;
    font-size: 1.25rem;
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__img {
    object-fit: cover;
  }

  &__icon {
    font-size: 4rem;
    margin-bottom: 0.85rem;
  }

  &__name {
    word-break: break-all;
  }

  @media (min-width: 576px) {
    height: 250px;
  }

  @media (min-width: 768px) {
    height: 190px;
  }
}
</style>
