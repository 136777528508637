<template>
  <div class="Recommendation">
    <BaseJumbotron title="Indicação de jurados" />
    <BaseContainer>
      <div class="RecommendationContent" v-show="!showSuccess">
        <article class="RecommendationContent__description mb-9">
          <h2 class="text-center text-lg-left h3 mb-7">Olá {{ formData.mobilizer_name }}!</h2>
          <p>Precisamos da sua ajuda para indicar jurados capacitados que possam avaliar os projetos dentro de cada uma das categorias do Prêmio Brasil Criativo.</p>
          <p>Lembrando que cada curador é responsável por sua categoria.</p>
          <p>Todos juntos por um Brasil mais Criativo!</p>
        </article>
        <RecommendationForm class="mb-7" :form-data="formData" :categories="categories" :was-validated="wasValidated" @formSubmit="handleSubmit" v-if="!getCategoriesErrorMessage"/>
      </div>
      <BaseSpinner v-show="loading"/>
      <b-alert fade dismissible variant="danger" class="mb-0" v-model="showError">
        <h5 class="h5">Erro!</h5>
        <p class="mb-0">Ocorreu um problema ao salvar. Tente novamente.</p>
      </b-alert>
      <b-alert fade dismissible variant="danger" class="mb-0" v-model="showGetCategoriesErrorMessage">
        <h5 class="h5">Erro!</h5>
        <p class="mb-0"><strong>Formulário desabilitado:</strong> {{ getCategoriesErrorMessage }}</p>
      </b-alert>
      <BaseSplashScreen variant="success" title="Indicação salva com sucesso!" btn-text="Indicar outra pessoa" @btnClicked="handleClick" v-show="showSuccess"/>
    </BaseContainer>
  </div>
</template>

<script>
import recommendation from "@/api/services/recommendation";
import BaseJumbotron from "@/components/BaseJumbotron";
import BaseContainer from "@/components/BaseContainer";
import BaseSpinner from "@/components/BaseSpinner";
import BaseSplashScreen from "@/components/BaseSplashScreen";
import RecommendationForm from "@/components/RecommendationForm";

export default {
  name: "Recommendation",
  components: {
    BaseJumbotron,
    BaseContainer,
    BaseSpinner,
    BaseSplashScreen,
    RecommendationForm
  },
  data() {
    return {
      formData: {
        mobilizer_name: "",
        mobilizer_email: "",
        category_name: "",
        curator_name: "",
        curator_email: "",
        curator_phone: "",
        curator_facebook: "",
        curator_linkedin: ""
      },
      wasValidated: false,
      showSuccess: false,
      showError: false,
      showGetCategoriesErrorMessage: false,
      getCategoriesErrorMessage: "",
      loading: false
    }
  },
  computed: {
    categories() {
      return this.$store.state.category.categories;
    }
  },
  methods: {
    handleSubmit(e, formData) {
      this.wasValidated = true;
      if (e.target.checkValidity()) {
        this.loading = true;
        recommendation
          .save(formData)
          .then(() => {
            this.showSuccess = true;
          })
          .catch(() => {
            this.showError = true;
          })
          .finally(() => {
            this.loading = false;
          })
      } else {
        this.loading = false;
      }
    },
    handleClick() {
      for(let property in this.formData) {
        this.formData[property] = "";
      }
      this.formData.mobilizer_name = this.$route.query.name;
      this.formData.mobilizer_email = this.$route.query.email;
      this.wasValidated = false;
      this.showSuccess = false;
    }
  },
  mounted() {
    if (!this.$route.query.name && !this.$route.query.email) {
      this.$router.push({ name: "home" });
    } else {
      this.loading = true;
      this.formData.mobilizer_name = this.$route.query.name;
      this.formData.mobilizer_email = this.$route.query.email;
      this.$store.dispatch("category/getCategories")
        .catch(({ response: { data: { responseData } } }) => {
          this.getCategoriesErrorMessage = responseData.errorMessage;
          this.showGetCategoriesErrorMessage = true;
        })
        .catch(() => {
          this.getCategoriesErrorMessage = "Serviço indisponível.";
          this.showGetCategoriesErrorMessage = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.Recommendation {
  margin-bottom: 150px;
}
</style>
