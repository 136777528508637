

<template>
  <div>
    <!-- LightWidget Widget -->
    <iframe 
      src="//lightwidget.com/widgets/e3fe4d7759b85a3e91acae3bb07f9ac4.html"
      scrolling="no"
      allowtransparency="true"
      class="lightwidget-widget"
      style="width:100%;border:0;overflow:hidden;">
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'LightWidget',
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://cdn.lightwidget.com/widgets/lightwidget.js';
    script.async = true;
    document.head.appendChild(script);
  }
}
</script>

<style>
/* Adicione qualquer estilo específico aqui */
</style>
