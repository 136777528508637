<template>
  <div class="mobilizers section" data-anchor="parcerias">
    <b-row class="mx-0 min-vh-100">
      <b-col class="p-11">
        <h2 class="h1 mb-11 text-break">Parcerias</h2>
        <CardRowItems :items="filteredMobilizers || mobilizers" item-img="avatar_url" item-title="name" item-bio="bio"/>
        <!-- <b-modal id="mobilizerDetailModal" dialog-class="fullscreen-modal-dialog" content-class="fullscreen-modal-content p-lg-0" body-class="fullscreen-modal-body p-0" hide-header hide-footer v-if="mobilizer">
          <template slot="default">
            <b-row class="min-vh-100 w-100">
              <b-col lg="4" class="d-none d-lg-block pr-0">
                <img :src="mobilizer.avatar_url" class="card-row-item-detail__img">
              </b-col>
              <b-col lg="8" class="p-lg-11">
                <b-row class="mb-7">
                  <b-col cols="12" lg="2" order-lg="2" class="mb-7 mb-lg-0">
                    <b-link class="close fullscreen-close" href="#" @click="handleMobilizerDismiss">×</b-link>
                  </b-col>
                  <b-col cols="12" lg="10" order-lg="1" class="mb-7 mb-lg-0">
                    <ul class="list-unstyled mb-0 mx-n3" v-if="mobilizerRegion">
                      <li class="d-inline-block h5 px-3">{{ mobilizerRegion.name }}</li>
                    </ul>
                    <h2 class="h1 mb-0">{{ mobilizer.name }}</h2>
                  </b-col>
                </b-row>
                <b-row class="mb-8">
                  <b-col>
                    <article>{{ mobilizer.bio }}</article>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="social-links mb-8">
                      <a class="social-link" v-if="mobilizer.facebook" :href="mobilizer.facebook"><i class="fab fa-facebook"></i></a>
                      <a class="social-link" v-if="mobilizer.twitter" :href="mobilizer.twitter"><i class="fab fa-twitter"></i></a>
                      <a class="social-link" v-if="mobilizer.instagram" :href="mobilizer.instagram"><i class="fab fa-instagram"></i></a>
                      <a class="social-link" v-if="mobilizer.linkedin" :href="mobilizer.linkedin"><i class="fab fa-linkedin"></i></a>
                      <b-button variant="link" class="social-link" v-clipboard:copy="linkToShare" v-clipboard:success="onCopy"><i class="fas fa-share"></i></b-button>
                    </div>
                    <p v-show="showLinkCopied">Copiado! <i class="fas fa-check-circle text-success"></i></p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </b-modal> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CardRowItems from "@/components/CardRowItems";
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';

VueClipboard.config.autoSetContainer = true; // in order to work with modals
Vue.use(VueClipboard);

export default {
  name: "Mobilizers",
  components: {
    CardRowItems
  },
  props: {
    mobilizerSlug: String
  },
  data() {
    return {
      mobilizer: {},
      mobilizerRegion: {},
      currentRegionId: "",
      filteredMobilizers: null,
      regions: [],
      linkToShare: "",
      showLinkCopied: false
    };
  },
  computed: {
    mobilizers: {
      get() {
        return this.$store.state.mobilizer.mobilizers;
      }
    },
    filteredMobilizerBySlug: function () {
      return this.mobilizers.filter(mobilizer => mobilizer.slug === this.mobilizerSlug, this);
    }
  },
  methods: {
    onCopy: function () {
      this.showLinkCopied = true;
    },
    addFilter: function (item) {
      if (item.id) {
        return this.filteredMobilizers = this.mobilizers.filter(mobilizer => mobilizer.region_id.includes(item.id));
      } else {
        return this.filteredMobilizers = null;
      }
    },
    removeFilter: function () {
      return (this.filteredMobilizers = null);
    },
    // handleMobilizerClick: function () {
    //   // this.$router.push({ name: "mobilizerDetail", hash: "#mobilizadores", params: { mobilizerSlug: mobilizer.slug } });
    //   // this.mobilizer = mobilizer;
    //   // this.mobilizerRegion = this.regions.filter(region => mobilizer.region_id.includes(region.id))[0];
    //   // this.$bvModal.show("mobilizerDetailModal");
    //   // history.pushState({}, null, `/mobilizador/${mobilizer.slug}`)
    // },
    handleMobilizerDismiss: function () {
      this.$bvModal.hide("mobilizerDetailModal");
      setTimeout(() => this.$router.push({ name: "home", hash: "#mobilizadores" }), 200);
      // history.pushState({}, null, `/`)
    },
    handleCurrentRegionIdChange: function () {
      if (this.currentRegionId) {
        return this.filteredMobilizers = this.mobilizers.filter(mobilizer => mobilizer.region_id.includes(this.currentRegionId));
      } else {
        return this.filteredMobilizers = null;
      }
    }
  },
  mounted() {
    this.$store.dispatch("mobilizer/fetch").then(() => {
      const regions = this.mobilizers
        .map(mobilizer => mobilizer.region_id)
        .map((e, i, array) => array.indexOf(e) === i && i)
        .filter(e => this.mobilizers[e])
        .map(e => {
          return {
            id: this.mobilizers[e].region_id,
            name: this.mobilizers[e].region_name
          };
        });
      this.regions = regions;
      if (this.mobilizerSlug) {
        this.mobilizer = this.filteredMobilizerBySlug[0];
        this.mobilizerRegion = this.regions.filter(region => this.mobilizer.region_id.includes(region.id), this)[0];
        this.$bvModal.show("mobilizerDetailModal");
        this.linkToShare = `https://www.premiobrasilcriativo.com.br${this.$route.path}`;
      }
    });
  },
  // metaInfo () {
  //   const mobilizerTitle = this.mobilizer ? this.mobilizer.name : "";
  //   const mobilizerImg = this.mobilizer ? this.mobilizer.avatar_url : "";
  //   const mobilizerDescription = this.mobilizer ? this.mobilizer.bio : "";
  //   return {
  //     title: mobilizerTitle,
  //     meta: [
  //       // { vmid: "og:type", property: "og:type", content: "article" },
  //       { vmid: "og:title", property: "og:title", content: mobilizerTitle },
  //       { vmid: "og:description", property: "og:description", content: mobilizerDescription },
  //       { vmid: "og:image", property: "og:image", content: mobilizerImg },
  //       { vmid: "twitter:title", name: "twitter:title", content: mobilizerTitle },
  //       { vmid: "twitter:description", name: "twitter:description", content: mobilizerDescription },
  //       { vmid: "twitter:image", name: "twitter:image", content: mobilizerImg },
  //       { vmid: "itemName", itemprop: "name", content: mobilizerTitle },
  //       { vmid: "itemDescription", itemprop: "description", content: mobilizerDescription },
  //       { vmid: "itemImage", itemprop: "image", content: mobilizerImg }
  //     ]
  //   }
  // },
};
</script>

<style lang="scss" scoped>
.card-row-item-detail__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
