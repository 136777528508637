<template>
  <div id="app">
    <transition name="fade">
      <router-view :key="$route.path"></router-view>
    </transition>
    <transition name="fade">
      <ScrollTopButton v-scroll="handleScroll" v-show="showScrollTopButton" href="#inicio" />
    </transition>
  </div>
</template>

<script>
import ScrollTopButton from "@/components/ScrollTopButton";
// const getResUrl = (resPath) => window.location.origin + require(resPath);  //not working

export default {
  name: "app",
  metaInfo() {
    const pageTitle       = "Faça parte da 4ª edição da premiação oficial da Economia Criativa brasileira";
    const siteDescription = "Faça parte da 4ª edição da premiação oficial da Economia Criativa brasileira e conecte-se ao maior ecossistema criativo do país.";
    const pageImage       = process.env.VUE_APP_DOMAIN + require("@/assets/images/og_image.jpg");
    const pageImageAlt    = "Pessoas aguardam sentadas em poltronas de um auditório enquanto olham para um palco vazio contendo o logotipo do Prêmio Brasil Criativo.";

    return {
      // https://alligator.io/vuejs/vue-seo-tips/
      title: pageTitle,
      titleTemplate: "%s | Prêmio Brasil Criativo",
      meta: [
        // Accent color for mobile browsers
        { vmid: "theme-color", name: "theme-color", content: "#000000" },

        // General meta tags
        { vmid: "generic:title",       name: "title",       content: pageTitle },
        { vmid: "generic:description", name: "description", content: siteDescription },

        // OpenGraph data (Most widely used)
        // The list of types is available here: http://ogp.me/#types

        { vmid: "og:site_name",    property: "og:site_name",    content: "Prêmio Brasil Criativo" },
        { vmid: "og:locale",       property: "og:locale",       content: "pt_BR" },
        { vmid: "og:type",         property: "og:type",         content: "website" },
        { vmid: "og:url",          property: "og:url",          content: process.env.VUE_APP_DOMAIN },
        { vmid: "og:title",        property: "og:title",        content: pageTitle },
        { vmid: "og:description",  property: "og:description",  content: siteDescription },
        { vmid: "og:image",        property: "og:image",        content: pageImage },
        { vmid: "og:image:alt",    property: "og:image:alt",    content: pageImageAlt },
        { vmid: "og:image:type",   property: "og:image:type",   content: "image/jpeg" },
        { vmid: "og:image:width",  property: "og:image:width",  content: "1280" },
        { vmid: "og:image:height", property: "og:image:height", content: "628" },

        // Twitter card
        { vmid: "twitter:card",        name: "twitter:card",        content: "summary" },
        { vmid: "twitter:site",        name: "twitter:site",        content: process.env.VUE_APP_DOMAIN },
        { vmid: "twitter:title",       name: "twitter:title",       content: pageTitle },
        { vmid: "twitter:description", name: "twitter:description", content: siteDescription },
        { vmid: "twitter:image",       name: "twitter:image",       content: pageImage },
        { vmid: "twitter:image:alt",   name: "twitter:image:alt",   content: pageImageAlt },

        // Google / Schema.org markup:
        { vmid: "schemaorg:name",        itemprop: "name",        content: pageTitle },
        { vmid: "schemaorg:description", itemprop: "description", content: siteDescription },
        { vmid: "schemaorg:image",       itemprop: "image",       content: pageImage },

        { name: "description",  content: siteDescription },
      ]
    }
  },
  components: {
    ScrollTopButton
  },
  data() {
    return {
      showScrollTopButton: window.innerWidth > 1200 || window.scrollY > 200
    }
  },
  directives: {
    scroll: {
      inserted: function (el, binding) {
        let f = function (evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener("scroll", f)
          }
        }
        window.addEventListener("scroll", f)
      }
    }
  },
  methods: {
    handleScroll: function () {
      this.showScrollTopButton = window.innerWidth > 1200 || window.scrollY > 200;
    },
  }
}
</script>

<style lang="scss">
@import url("//use.fontawesome.com/releases/v5.8.1/css/all.css");

/* transition settings */
.fade {
  &-enter,
  &-leave-to {
    opacity: 0;
  }
  &-enter-active,
  &-leave-active {
    transition: opacity .15s ease-in-out;
  }
}
</style>
