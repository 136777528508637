import { render, staticRenderFns } from "./PostDetail.vue?vue&type=template&id=433502c7"
import script from "./PostDetail.vue?vue&type=script&lang=js"
export * from "./PostDetail.vue?vue&type=script&lang=js"
import style0 from "./PostDetail.vue?vue&type=style&index=0&id=433502c7&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PostDetail.vue?vue&type=custom&index=0&blockType=meta&property=og%3Adescription&content=PR%C3%8AMIO%20BRASIL%20CRIATIVO%3A%20UMA%20D%C3%89CADA%20DE%20CONQUISTAS%20PARA%20A%20ECONOMIA%20CRIATIVA%20BRASILEIRA"
if (typeof block0 === 'function') block0(component)
import block1 from "./PostDetail.vue?vue&type=custom&index=1&blockType=meta&property=%E2%80%9Dog%3Aimage%E2%80%9D&content=%E2%80%9Dhttps%3A%2F%2Fprojecthub.com.br%2Ffiles%2Fmedia%2Foriginals%2Fimage.png%E2%80%9D"
if (typeof block1 === 'function') block1(component)

export default component.exports