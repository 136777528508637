<template>
  <div class="card-row-items-wrapper">
    <b-row class="card-row-items pb-10 mx-n11 mx-lg-0" no-gutters>
      <b-col class="px-2 mb-3" cols="10" lg="3" v-for="(item, index) in itemsWithImage" :key="index">
        <template v-if="itemsRouteTo && itemsRouteHash && itemSlug">
          <router-link :to="`${itemsRouteTo}/${item[itemSlug]}${itemsRouteHash}`">
            <div class="card h-100">
              <template v-if="item[itemImg][itemImgSub]">
                <b-img-lazy class="card-img card-jurado card-img-translucid card-row-item__img" :src="item[itemImg][itemImgSub]" alt="Imagem de capa" />
              </template>
              <template v-else>
                <b-img-lazy class="card-img card-img-translucid card-row-item__img" :src="item[itemImg]" alt="Imagem de capa" />
              </template>
              <div class="card-img-overlay card-img-overlay-hover">
                <div class="card-text p">{{ item[itemMiniBio] }}</div>
              </div>
            </div>
            <div class="card-text h4 mt-4">{{ item[itemTitle] }}</div>
          </router-link>
        </template>
        <template v-else>
          <template v-if="item['twitter']">
            <a :href="item['twitter']" target="_blank">
              <div class="card h-100">
                <template v-if="item[itemImg][itemImgSub]">
                  <b-img-lazy class="card-img card-img-translucid card-row-item__img" :src="item[itemImg][itemImgSub]" alt="Imagem de capa2" />
                </template>
                <template v-else>
                  <b-img-lazy class="card-img card-moblizer card-img-translucid card-row-item__img" :src="item[itemImg]" alt="Imagem de capa2" />
                </template>
                <div class="card-img-overlay card-img-overlay-hover" v-html="item['bio']">
                  <div class="card-text text-break"></div>
                </div>
              </div>
            </a>
          </template>
          <template v-else>
              <div class="card h-100">
                <template v-if="item[itemImg][itemImgSub]">
                  <b-img-lazy class="card-img card-img-translucid card-row-item__img" :src="item[itemImg][itemImgSub]" alt="Imagem de capa2" />
                </template>
                <template v-else>
                  <b-img-lazy class="card-img card-moblizer card-img-translucid card-row-item__img" :src="item[itemImg]" alt="Imagem de capa2" />
                </template>
                <div class="card-img-overlay card-img-overlay-hover" v-html="item['bio']">
                  <div class="card-text text-break"></div>
                </div>
              </div>
          </template>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "CardRowItems",
  data() {
    return {}
  },
  computed: {
    itemsWithImage() {
      return this.items.filter(item => item[this.itemImg]);
    }
  },
  props: {
    items: Array,
    itemsRouteTo: String,
    itemsRouteHash: String,
    itemImg: String,
    itemImgSub: String,
    itemTitle: String,
    itemMiniBio: String,
    itemSlug: String
  }
}
</script>

<style lang="scss" scoped>
.card-row-items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 70vh;
  overflow: auto hidden;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  &:before,
  &:after {
    display: block;
    content: '';
    padding-left: 1.25rem;

    @media (min-width: 992px) {
      padding-left: 0;
      margin-left: -0.375rem;
    }
  }
}
.card-row-item__img {
  width: 90%;
  margin: auto;
}

::-webkit-scrollbar {
  @media (min-width: 992px) {
    height: 12px;
    background: #FFF;
    border-radius: 10px;

    &-thumb {
      background: #000;
      border: 1px solid #FFF;
      border-radius: 10px;
    }
  }
}

.card-img-overlay-hover {
  color: transparent;
  transition: color .15s ease-in-out;


  &:hover {
    color: inherit;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

</style>
