import axios from 'axios'
import { getServerURL } from '@/const'

const state = {
  category: {},
  categories: [],
  loading: false
}

const getters = {}

const actions = {
  getCategories({ commit }) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      const currentYear = '2022';
      // const currentYear = (new Date()).getFullYear(); // it breaks when year changes
      axios
        .get(getServerURL(`/categories?edition=${currentYear}`))
        .then(response => {
          const categories = response.data.responseData.categories
          commit('setCategories', categories)
          commit('finishLoading')
          resolve(response)
        })
        .catch(error => {
          commit('finishLoading')
          reject(error)
        })
    })
  }
}

const mutations = {
  startLoading(state) {
    state.loading = true
  },
  finishLoading(state) {
    state.loading = false
  },
  setCategory(state, category) {
    state.category = category
  },
  setCategories(state, categories) {
    state.categories = categories
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
